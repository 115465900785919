<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0">All Users</h4>
        </div>
        <div class="d-flex gap-2 mt-3 mt-md-0">
            <div class="align-items-center gap-2 ml-auto">
            <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Search"
                v-model="meta.search" @keypress.enter="search()" />
        </div>
            <button type="button" class="mt-auto btn btn-primary d-flex align-items-center gap-2"
            @click="addUser()"><i class="ri-add-line fs-18 lh-1"></i>Add New User
        </button>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-striped text-nowrap table-hover table-bottom-border mb-0">
                            <thead>
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center">Sl No.</th>
                                    <th @click="sort('name')">Name
                                        <span>
                                            <i v-if="meta.keyword == 'name' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'name' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('email')">E-Mail
                                        <span>
                                            <i v-if="meta.keyword == 'email' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'email' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('mobile_no')">Mobile No.
                                        <span>
                                            <i v-if="meta.keyword == 'mobile_no' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'mobile_no' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Police Stations</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user, key in user_list" :key="key">
                                    <td class="text-center">{{ meta.from + key }}</td>
                                    <td> {{ user.name }}</td>
                                    <td>{{ user.email }}</td>
                                    <td>{{ user.mobile_no }}</td>
    
                                    <td class="text-center">
                                        <div class="form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" :id="'user' + user.user_id" :checked="user.status" :value="user.status" @change="deleteUser(user.user_id)" />
                                            <label class="custom-control-label" :for="'user' + user.user_id"></label>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <!-- <ul class="station_ul" v-if="user.UserPoliceStation?.length">
                                            <li class="no-marker" v-for="police_station, key in user.UserPoliceStation" :key="key">
                                                {{ police_station.police_station[0].police_station }}
                                            </li>
                                        </ul> -->
                                        <a href="javascript:void(0)" title="View PoliceStations"data-bs-toggle="modal" data-bs-target="#exampleModal" @click="getUserPoliceStations(user.user_id)"
                                                class="text-primary me-2"><i class="ri-eye-line fs-18 lh-1"></i></a>
                                    </td>
                                    <td class="text-center">
                                        <nav v-if="user.status">
                                            <a href="javascript:void(0)" @click="editUser(user.user_id)"
                                                class="text-primary me-2"><i class="ri-edit-line fs-18 lh-1"></i></a>
                                        </nav>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                        @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
<!-- model -->
    <div class="modal modal-lg fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">User Police Stations</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive mb-2">
                        <input type="email" class="form-control mb-2" id="exampleFormControlInput1" placeholder="Search"
                    v-model="police_Station_meta.search" @keypress.enter="modelsearch()" />
                        <table class="table table-striped text-nowrap table-hover table-bottom-border mb-0">
                            <thead>
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center">Sl No.</th>
                                    <th @click="modelsort('police_station')">Police Station
                                        <span>
                                            <i v-if="meta.keyword == 'police_station' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'police_station' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="modelsort('division')">Division
                                        <span>
                                            <i v-if="meta.keyword == 'division' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'division' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="modelsort('sub_division')">Sub Division
                                        <span>
                                            <i v-if="meta.keyword == 'sub_division' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'sub_division' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="result, key in results" :key="key">
                                    <td class="text-center">{{ police_Station_meta.from + key }}</td>
                                    <td> {{ result.police_station }} </td>
                                    <td> {{ result.division.division }} </td>
                                    <td> {{ result.sub_division.sub_division }} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <Pagination :maxPage="police_Station_meta.maxPage" :totalPages="police_Station_meta.lastPage" :currentPage="police_Station_meta.page" @pagechanged="onmodelPageChange" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="discard()">Close</button>
                    <!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="updateCaseStage()">Submit</button> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
export default {
    components: {
        Pagination
    },
    name: "Users",
    data() {
        return {
            user_list: [],
            status: true,
            meta: {
                search: '',
                order_by: "asc",
                keyword: "user_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
            },
            police_Station_meta: {
                search: '',
                order_by: "asc",
                keyword: "police_station_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
                user_id: ''
            },
            results:[],
           
        }
    },
    beforeRouteEnter(to, from, next) {
            next((vm) => {
                if(from.name != "UserUpdate" && from.name != "UserCreate"){
                    vm.$store.commit("setCurrentPage", vm.meta.page)
                }else{
                    vm.meta.page = vm.$store.getters.current_page
                }
            });
        },
    mounted() {
        this.getUsers();
    },
    methods: {
        getUserPoliceStations(user_id) {
                    let vm = this;
                    vm.police_Station_meta.user_id = user_id
                    let uri = { uri: "paginateUserPoliceStations", data: vm.police_Station_meta };
                    vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.results = response.data.data
                        vm.police_Station_meta.totalRows = response.data.meta.total;
                        vm.police_Station_meta.lastPage = response.data.meta.last_page;
                        vm.police_Station_meta.from = response.data.meta.from;
                        vm.police_Station_meta.maxPage = vm.police_Station_meta.lastPage >= 3 ? 3 : vm.police_Station_meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                },
        getUsers() {
            let vm = this;
            let uri = { uri: "paginateUsers", data: vm.meta };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.user_list = response.data.data
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    console.log("error", error)
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        editUser(user_id) {
            this.$store.commit("setCurrentPage", this.meta.page)
            this.$router.push("/user_update/" + user_id);
        },

        addUser() {
            this.$store.commit("setCurrentPage", this.meta.page)
            this.$router.push('/user_create');
        },

        deleteUser(user_id) {
            let vm = this;
            let uri = { uri: "deleteUser", data: { user_id: user_id } };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.$store.dispatch('success', response.data.message);
                    vm.getUsers();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        // getUserPoliceStations(user) {
        //     this.$router.push('/Userpolicestation/' +user);
        // },

        onPageChange(page) {
            this.meta.page = page;
            this.getUsers();
        },

        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.getUsers();
        },
        search() {
            this.meta.page = 1;
            this.getUsers();
        },
        onmodelPageChange(page){
            this.police_Station_meta.page = page;
            this.getUserPoliceStations(this.police_Station_meta.user_id);
        },

        modelsort(field) {
            this.police_Station_meta.keyword = field;
            this.police_Station_meta.order_by = this.police_Station_meta.order_by == "asc" ? "desc" : "asc";
            this.getUserPoliceStations(this.police_Station_meta.user_id);
        },
        modelsearch() {
            this.police_Station_meta.page = 1;
            this.getUserPoliceStations(this.police_Station_meta.user_id);
        },
        discard(){
            this.police_Station_meta.search = '';
        }
    }

}
</script>
<style scoped>
.card-one {
    border-width: 0px;
}

@media only screen and (max-width: 767px) {
    .gap-2 {
        margin-bottom: 0.5rem;
    }
}

.no-marker{
    list-style-type: none; /* Removes bullet or numbering */
  padding-left: 0; /* Optional: removes left padding */
}
.station_ul{
    padding-left: 0;
}
</style>
