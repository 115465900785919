<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0" v-if="!update_status"><router-link to="/users" type=button><i
                        class="ri-arrow-left-line menu_icon text-dark fs-18 lh-1 me-2"></i></router-link>Update User
            </h4>
            <h4 class="main-title mb-0" v-else><router-link to="/users" type="button"><i
                        class="ri-arrow-left-line menu_icon text-dark fs-18 lh-1 me-2"></i></router-link>Create User
            </h4>

        </div>
        <div class="d-flex gap-2 mt-3 mt-md-0">
            <router-link to="/users" class="btn btn-primary float-end"><i class="ri-menu-line fs-18 lh-1"></i>
                Users</router-link>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <form class="card card-one" @submit.prevent="submitForm()">
                <!-- <div class="card-header d-md-flex align-items-center justify-content-between ">
                    <h4 v-if="!update_status" style="display: inline;" class="mt-1">Update User</h4>
                    <h4 v-else style="display: inline;" class="mt-1">New User</h4>
                    <router-link to="/users" class="btn btn-primary float-end"><i class="ri-menu-line fs-18 lh-1"></i> Users</router-link>
                </div> -->
                <div class="card-body">
                    <div class="d-md-flex align-items-center justify-content-between">
                        <div class="flex-fill">
                            <div class="row">
                                <div class="col-12">
                                    <!-- <div class="row"> -->
                                    <!-- <div class="col-9"> -->
                                    <div class="row g-3">
                                        <div class="col-4">
                                            <label class="form-label">Name</label>
                                            <span class="text-danger">*</span>
                                            <input type="text" class="form-control"
                                                :class="{ 'is-invalid': errors.name }" placeholder="Enter user name"
                                                v-model="user.name" ref="name" />
                                            <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0]
                                                }}</span>
                                        </div>
                                        <div class="col-4">
                                            <label class="form-label">Is Admin</label>
                                            <span class="text-danger">*</span>
                                            <select class="form-control" :class="{ 'is-invalid': errors.is_admin }"
                                                v-model="user.is_admin">
                                                <option value="">Select Status</option>
                                                <option value=true>Yes</option>
                                                <option value=false>No</option>
                                            </select>
                                            <span v-if="errors.is_admin" class="invalid-feedback">{{ errors.is_admin[0]
                                                }}</span>
                                        </div>
                                        <div class="col-4">
                                            <label class="form-label">Police Station</label>
                                            <span class="text-danger">*</span>
                                            <MultiSelect v-model="selected_police_stations" filter
                                                optionLabel="police_station" :options="police_stations"
                                                placeholder="Select Police Station" :maxSelectedLabels="3" class=" "
                                                :class="{ 'is-invalid': errors.police_station_id }"
                                                style="height: 35px;width: 100%"
                                                @change="PoliceStaionFilter(selected_police_stations)" />
                                            <span v-if="errors.police_station_id" class="invalid-feedback">{{
                                                errors.police_station_id[0]
                                                }}</span>
                                        </div>
                                        <div class="col-6 ">
                                            <label class="form-label">Email</label>
                                            <span class="text-danger">*</span>
                                            <input type="email" class="form-control "
                                                :class="{ 'is-invalid': errors.email }" placeholder="Enter user email"
                                                v-model="user.email" />
                                            <span v-if="errors.email" class="invalid-feedback">{{
                                                errors.email[0] }}</span>
                                        </div>
                                        <div class="col-6 ">
                                            <label class="form-label">Mobile No.</label>
                                            <span class="text-danger">*</span>
                                            <input type="text" class="form-control "
                                                :class="{ 'is-invalid': errors.mobile_no }"
                                                placeholder="Enter user mobile number..." v-model="user.mobile_no"
                                                @keyup="generateName()" />
                                            <span v-if="errors.mobile_no" class="invalid-feedback">{{
                                                errors.mobile_no[0] }}</span>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                    <!-- <div class="col-3" style="display: flex; align-items: center;">
                                            <div class="d-block align-items-start align-items-sm-center gap-4">
                                                <img :src="user.avatar" alt="user-avatar" class="d-block rounded m-auto" height="180" width="150" id="uploadedAvatar" />
                                                <div class="button-wrapper text-center">
                                                    <label for="upload" class="btn btn-primary me-2 mt-2" tabindex="0">
                                                        <span class="d-none d-sm-block">Upload Photo</span>
                                                        <i class="bx bx-upload d-block d-sm-none"></i>
                                                        <input type="file" id="upload" class="account-file-input" hidden
                                                            accept="image/png, image/jpeg"
                                                            @change="onImageChange($event)" />
                                                    </label>
                                                </div>
                                                <span class="invalid-feedback">{{ }}</span>
                                            </div>
                                        </div> -->
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-end">
                    <div class="row">
                        <div class="col-12">
                            <button type="button" class="btn btn-danger me-2" @click="discard()"><i
                                    class="ri-prohibited-line me-1"></i>Discard</button>
                            <!-- <button v-if="update_status" class="btn btn-primary float-end" @click="updateUser()">Update User</button>
                            <button v-else class="btn btn-primary float-end" @click="addUser()">Add User</button> -->
                            <button type="submit" class="btn btn-primary">
                                <span v-if="update_status"> <i class="ri-save-line me-1"></i> Submit</span>
                                <span v-else> <i class="ri-save-line me-1"></i> Update </span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import MultiSelect from 'primevue/multiselect';
export default {
    components: {
        MultiSelect
    },
    data() {
        return {
            user: {
                user_id: '',
                name: 'PS_',
                email: '',
                mobile_no: '',
                address: '',
                avatar: '/assets/img/user.png',
                police_station_id: [],
                is_admin: false,
                deleted_police_station_id: [],
            },
            selected_police_stations: [],
            police_stations: [],
            errors: [],
            image: '',
            update_status: true,
            user_police_station_ids: [],
            route_name: ""
        }
    },

    beforeRouteEnter(to, form, next) {
        next(vm => {

            if (to.name == 'UserUpdate') {
                vm.route_name = to.name
                vm.update_status = false;
                vm.user.user_id = to.params.user_id;
            }
            else {
                vm.$refs.name.focus();
            }
            vm.getPoliceStations();
        })
    },

    methods: {
        submitForm() {
            let vm = this;
            if (vm.update_status) {
                vm.addUser();
            } else {
                vm.updateUser();
            }
        },
        generateName() {
            let vm = this;
            if (vm.user.name.includes('PS_')) {
                vm.user.name = "";
                vm.user.name = 'PS_' + vm.user.mobile_no;
            }
        },
        getUser() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store.dispatch('post', { uri: 'getUser', data: this.user })
                .then(response => {
                    loader.hide();
                    vm.user = response.data.data;
                    if (vm.user.UserPoliceStation?.length) {
                        vm.user.UserPoliceStation.filter(function (ele) {
                            vm.police_stations.map(function (police_station) {
                                if (police_station.police_station_id == ele.police_station_id) {
                                    vm.selected_police_stations.push(police_station)
                                }
                            })
                        })
                    }
                    vm.user.police_station_id = []
                    if (vm.selected_police_stations.length) {
                        vm.selected_police_stations.map(function (ele) {
                            vm.user.police_station_id.push(ele.police_station_id)
                        })
                    }
                    vm.user_police_station_ids = vm.user.police_station_id

                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getUserPoliceStations() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getPoliceStations" };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.police_stations = response.data.data;

                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getPoliceStations() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getPoliceStations" };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.police_stations = response.data.data;
                    if (vm.route_name != "") {
                        vm.getUser()
                    }
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        PoliceStaionFilter(selected_police_stations) {
            let vm = this;
            vm.user.police_station_id = []
            selected_police_stations.map(function (ele) {
                vm.user.police_station_id.push(ele.police_station_id)
            })
            let deleted_police_stations = vm.user_police_station_ids.filter(id => !vm.user.police_station_id.includes(id));
            vm.user.deleted_police_station_id = deleted_police_stations
        },
        addUser() {
            let vm = this;
            if (vm.user.is_admin == "true") {
                vm.user.is_admin = true
            }
            if (vm.user.is_admin == "false") {
                vm.user.is_admin = false
            }
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'addUser', data: vm.user })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    this.errors = [];
                    this.$router.push('/users');
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        updateUser() {
            let vm = this;
            if (vm.user.is_admin == "true") {
                vm.user.is_admin = true
            }
            if (vm.user.is_admin == "false") {
                vm.user.is_admin = false
            }
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'updateUser', data: vm.user })
                .then(response => {
                    loader.hide();
                    this.user = response.data.data;
                    this.$store.dispatch('success', response.data.message);
                    this.errors = [];
                    this.route_name = "";
                    this.$router.push('/users');
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        onImageChange(e) {
            let vm = this;
            vm.image = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(vm.image);
            reader.onload = (e) => {
                vm.user.avatar = e.target.result;
            };
        },


        discard() {
            let vm = this;
            vm.user.name = "";
            vm.user.email = "";
            vm.user.mobile_no = "";
            vm.user.police_station_id = [];
            vm.user.is_admin = false;
            vm.selected_police_stations = [];
            vm.$refs.name.focus();
            vm.errors = [];
            vm.status = true;
            this.route_name = "";
        },
    }
}
</script>
<style scoped>
input:focus,
select:focus {
    outline: none;
    box-shadow: 0 0 0 0 transparent;
}

.menu_icon {
    font-size: 20px;
    opacity: 0.85;
}

.multiselect {
    position: relative;
}

.multiselect ul {
    border: 1px solid #ddd;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 8px 8px;
    top: -0.1rem;
    width: 100%;
    list-style: none;
    max-height: 150px;
    overflow: auto;
    background: white;
}

.is-invalid {
    border: 1px solid red;
}
</style>