<template>
    <div class="sidebar">
        <div class="sidebar-header gap-3">
            <a href="javascript:void(0)" class="sidebar-logo"><img src="assets/img/log11.png" class="img" width="40" height="40" /></a>
            <div>
                <a class="text-dark header-logo mb-0 mt-3">Muddemal</a>
                <p class="fs-12 font-gray">Bengaluru City Police</p>
            </div>
        </div>
        <div id="sidebarMenu" class="sidebar-body ps">
            <div class="nav-group show">
                <a href="javascript:void(0)" class="nav-label"><i class="ri-group-line me-2 menu_icon"></i> Main</a>
                <ul class="nav nav-sidebar">
                    <!-- <li class="nav-item">
                        <router-link to="/dashboard" class="nav-link"><i class="ri-add-box-line"></i> <span>Dashboard</span></router-link>
                    </li> -->
                    <li class="nav-item">
                        <router-link to="/muddemal" class="nav-link"><i class="ri-file-list-3-line"></i> <span>Muddemal</span></router-link>
                    </li>
                    <li class="nav-item" v-if="$store.getters.user.is_admin">
                        <router-link to="/users" class="nav-link"><i class="ri-group-line"></i><span>User Management</span></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/logs" class="nav-link"><i class="ri-download-2-line"></i><span>Downloads</span></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/downloaded_qr_codes" class="nav-link"><i class="ri-qr-code-line"></i><span>Downloaded QR Codes</span></router-link>
                    </li>
                </ul>
            </div>
            <div class="nav-group" v-if="$store.getters.user.is_admin">
                <a href="javascript:void(0)" class="nav-label"><i class="ri-settings-5-line me-2 menu_icon"></i> Configuration</a>
                <ul class="nav nav-sidebar">
                    <li class="nav-item">
                        <router-link to="/divisions" class="nav-link"><i class="ri-archive-drawer-fill"></i><span>Divisions</span></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/sub_divisions" class="nav-link"><i class="ri-archive-stack-fill"></i><span>Sub Divisions</span></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/police_stations" class="nav-link"><i class="ri-home-8-fill"></i><span>Police Stations</span></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/case_stages" class="nav-link"><i class="ri-bar-chart-2-line"></i><span>Case Stages</span></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/property_types" class="nav-link"><i class="ri-pie-chart-line"></i><span>Property Types</span></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/property_disposal_stages" class="nav-link"><i class="ri-bar-chart-horizontal-fill"></i><span>Property Disposal Stages</span></router-link>
                    </li>

                </ul>
            </div>
            <div class="nav-group show">
                <a href="javascript:void(0)" class="nav-label"><i class="ri-file-chart-line me-2 menu_icon"></i> Reports</a>
                <ul class="nav nav-sidebar">
                    <li class="nav-item">
                        <router-link to="/audit_report" class="nav-link"><i class="ri-file-check-line"></i><span>Audit Report</span></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/audit_report_year_wise" class="nav-link"><i class="ri-file-check-line"></i><span>Audit Report Year Wise</span></router-link>
                    </li>
                    <li class="nav-item" v-if="$store.getters.user.is_admin">
                        <router-link to="/property_details_report" class="nav-link"><i class="ri-government-line"></i><span>Property Details</span></router-link>
                    </li>
                    <li class="nav-item" v-if="$store.getters.user.is_admin">
                        <router-link to="/cases_status_report" class="nav-link"><i class="ri-suitcase-line"></i><span>Cases Status Year Wise</span></router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="sidebar-footer">
            <div class="sidebar-footer-top" id="sidebarFooterMenu">
                <div class="sidebar-footer-thumb">
                    <!-- <img src="assets/img/bg1.jpg" alt="" /> -->
                    <img v-if="$store.getters?.user?.avatar" :src="$store.getters?.user?.avatar" alt="User Image" />
                    <img v-else src="assets/img/bg1.jpg" alt="User Image" />
                </div>
                <div class="sidebar-footer-body">
                    <h6><a href="javascript:void(0)">{{ $store.getters.user.name }}</a></h6>
                    <p>{{ $store.getters.user.role }}</p>
                </div>
                <a id="sidebarFooterMenu" href="javascript:void(0)" class="dropdown-link"><i class="ri-arrow-down-s-line"></i></a>
            </div>
            <div class="sidebar-footer-menu">
                <nav class="nav">
                    <router-link to="/profile_update"><i class="ri-user-line"></i> Edit Profile</router-link>
                    <!-- <a href="javascript:void(0)"><i class="ri-edit-2-line"></i> Edit Profile</a> -->
                    <a href="javascript:void(0)" @click="logout()"><i class="ri-logout-box-r-line"></i> Log Out</a>
                </nav>
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        data(){
            return {

            }
        },
        methods: {
            logout(){
                let vm = this;
                this.$store.dispatch('post',{uri: 'logout'})
                .then(response => {
                    this.$store.dispatch('success', response.data.message);
                    this.$store.dispatch('logout');
                    this.$router.push('/login');
                })
                .catch(error => {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
            }
        }
    }
</script>
<style scoped>
    .ml-20 {
        margin-left: 20px;
    }
    .menu_icon {
        font-size: 20px;
        opacity: 0.85;
    }
</style>
