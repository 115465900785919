<template>
	<div v-if="$store.getters.user">
		<Sidebar></Sidebar>
		<div class="main main-app p-4 pt-2 mt-0 " style="background-color: #f8f8f8;">
			<!-- <a id="menuSidebar" href="#" class="menu-link me-3 me-lg-4 fs-24"><i class="ri-menu-2-fill"></i></a> -->
			<img src="assets/img/bg3.png" class="img2" style="position: absolute;">
			<div><a id="menuSidebar" href="#" class="menu-link me-3 me-lg-4"><i class="ri-menu-2-fill"></i></a></div>
			<router-view />
		</div>
	</div>
	<div v-else>
		<img src="assets/img/bg3.png" class="img2" style="position: absolute;">
		<router-view />
	</div>
</template>
<script>
import Sidebar from "./components/Sidebar.vue";
import Footer from "./components/Footer.vue";
export default {
	name: 'App',
	components: { Sidebar, Footer },
	created() {
		if (sessionStorage.getItem("user")) {
			this.$store.dispatch('setUser', JSON.parse(sessionStorage.getItem("user")))
			sessionStorage.removeItem('user')
		}
		if (sessionStorage.getItem("token")) {
			this.$store.dispatch('setToken', sessionStorage.getItem("token"))
			sessionStorage.removeItem('token')
		}
		if (sessionStorage.getItem("current_page")) {
			this.$store.dispatch('setCurrentPage', Number(sessionStorage.getItem("current_page")))
			sessionStorage.removeItem('current_page')
		}
		if (sessionStorage.getItem("search_keyword")) {
			this.$store.dispatch('setSearchKeyword', sessionStorage.getItem("search_keyword"))
			sessionStorage.removeItem('search_keyword')
		}
		if (sessionStorage.getItem("police_station_ids")) {
			this.$store.dispatch('setPoliceStationIds', JSON.parse(sessionStorage.getItem("police_station_ids")))
			sessionStorage.removeItem('police_station_ids')
		}
		if (sessionStorage.getItem("years")) {
			this.$store.dispatch('setYears', JSON.parse(sessionStorage.getItem("years")))
			sessionStorage.removeItem('years')
		}

		window.addEventListener("beforeunload", () => {
			sessionStorage.setItem("user", JSON.stringify(this.$store?.getters?.user))
			sessionStorage.setItem("token", this.$store?.getters?.token)
			sessionStorage.setItem("current_page", Number(this.$store?.getters?.current_page))
			sessionStorage.setItem("search_keyword", this.$store?.getters?.search_keyword)
			sessionStorage.setItem("police_station_ids", JSON.stringify(this.$store?.getters?.police_station_ids))
			sessionStorage.setItem("years", JSON.stringify(this.$store?.getters?.years))
		})
	}
};
</script>
<style scoped>
.img2 {
	opacity: 15%;
	width: 163px;
	height: 122px;
	bottom: 8px;
	right: 16px;
}

/* @media (min-width: 992px) {
    .main {
        margin-left: 240px;
        margin-top: 0;
    }
} */
.main {
	min-height: 100vh !important;
}

th {
	white-space: wrap;
}

input:focus,
select:focus {
	outline: none !important;
	box-shadow: 0 0 0 0 transparent !important;
}

/* @media(min-width:991px){
      #menuSidebar{
        display:none;
      }
    }
	#menuSidebar{
		font-size: 24px;
	} */
/* #menuSidebar{
		font-size: 24px;
	}  */


@media(min-width:991px) {
	#menuSidebar {
		display: none;
	}
}

#menuSidebar {
	font-size: 24px;
}
</style>