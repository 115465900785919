<template>
        <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
            <div>
                <h4 class="main-title mb-0"><router-link to="/users" type="button" ><i class="ri-arrow-left-line menu_icon text-dark fs-18 lh-1 me-2"></i></router-link>User Police Stations</h4>
            </div>
            <div class="d-flex gap-2 mt-3 mt-md-0">
                <div class="align-items-center gap-2 ml-auto">
                <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Search"
                    v-model="meta.search" @keypress.enter="search()" />
            </div>
            </div>
        </div>
        <div class="row g-3 mb-2">
            <div class="col-xl-12">
                <div class="card card-one">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped text-nowrap table-hover table-bottom-border mb-0">
                                <thead>
                                    <tr style="background-color: #f5f5f5;">
                                        <th class="text-center">Sl No.</th>
                                        <th @click="sort('police_station')">Police Station
                                            <span>
                                                <i v-if="meta.keyword == 'police_station' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                                <i v-else-if="meta.keyword == 'police_station' && meta.order_by == 'desc'"
                                                    class="ri-arrow-down-line"></i>
                                                <i v-else class="fas fa-sort"></i>
                                            </span>
                                        </th>
                                        <th @click="sort('division')">Division
                                            <span>
                                                <i v-if="meta.keyword == 'division' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                                <i v-else-if="meta.keyword == 'division' && meta.order_by == 'desc'"
                                                    class="ri-arrow-down-line"></i>
                                                <i v-else class="fas fa-sort"></i>
                                            </span>
                                        </th>
                                        <th @click="sort('sub_division')">Sub Division
                                            <span>
                                                <i v-if="meta.keyword == 'sub_division' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                                <i v-else-if="meta.keyword == 'sub_division' && meta.order_by == 'desc'"
                                                    class="ri-arrow-down-line"></i>
                                                <i v-else class="fas fa-sort"></i>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="result, key in results" :key="key">
                                        <td class="text-center">{{ meta.from + key }}</td>
                                        <td> {{ result.police_station }} </td>
                                        <td> {{ result.division.division }} </td>
                                        <td> {{ result.sub_division.sub_division }} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer">
                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </template>
    <script>
        import Pagination from "@/components/Pagination.vue";
        export default {
            components: {
                Pagination
            },
            name: "UserLogs",
            data() {
                return {
                    results: [],
                    status: true,
                    meta: {
                        search: '',
                        order_by: "asc",
                        keyword: "police_station_id",
                        per_page: 10,
                        totalRows: 0,
                        page: 1,
                        lastPage: 1,
                        from: 1,
                        maxPage: 1,
                        user_id: ''
                    },
                }
            },
            beforeRouteEnter(to, from, next) {
                next((vm) => {
                  vm.meta.user_id = to.params.user_id;
                  vm.getUserPoliceStations();
                });
            },
            methods: {
                getUserPoliceStations() {
                    let vm = this;
                    let uri = { uri: "paginateUserPoliceStations", data: vm.meta };
                    vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.results = response.data.data
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                },
                search() {
                    let vm = this;
                    vm.meta.page = 1;
                    vm.getUserPoliceStations();
                },

                onPageChange(page) {
                    let vm = this;
                    vm.meta.page = page;
                    vm.getUserPoliceStations();
                },
                sort(field) {
                    let vm = this;
                    vm.meta.keyword = field;
                    vm.meta.order_by = vm.meta.order_by == "asc" ? "desc" : "asc";
                    vm.getUserPoliceStations();
                },
            }
        }
    </script>