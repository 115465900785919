import { createStore } from 'vuex'
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import axios from "axios";

export default createStore({
	state: {
		apiUrl: "https://muddemal.patterneffects.in/",
		// apiUrl: "http://localhost:8000/",
		// apiUrl: "http://192.168.0.141/muddemal/laravel/public/",
		// apiUrl: "http://192.168.0.214/muddemal/laravel/public/",
		user: null,
		token: "",
		current_page: '',
		search_keyword: '',
		police_station_ids: [],
		years: [],
		page: ''
	},
	getters: {
		user(state) {
			return state.user;
		},
		token(state) {
			return state.token;
		},
		page(state) {
			return state.page;
		},
		current_page(state) {
			return state.current_page;
		},
		search_keyword(state) {
			return state.search_keyword;
		},
		police_station_ids(state) {
			return state.police_station_ids;
		},
		years(state) {
			return state.years;
		},
	},
	mutations: {
		setUser(state, user) {
			state.user = user;
		},
		setToken(state, token) {
			state.token = token;
		},
		setPage(state, page) {
			state.page = page;
		},
		setCurrentPage(state, current_page) {
			state.current_page = current_page;
		},
		setSearchKeyword(state, search_keyword) {
			state.search_keyword = search_keyword;
		},
		setPoliceStationIds(state, police_station_ids) {
			state.police_station_ids = police_station_ids;
		},
		setYears(state, years) {
			state.years = years;
		},
	},
	actions: {
		async setUser(context, payload) {
			sessionStorage.setItem("user", JSON.stringify(payload))
			await context.commit('setUser', payload);
		},
		async setToken(context, payload) {
			sessionStorage.setItem("token", payload)
			await context.commit('setToken', payload);
		},
		async setPage(context, payload) {
			await context.commit('setPage', payload);
		},
		async setCurrentPage(context, payload) {
			await context.commit('setCurrentPage', payload);
		},
		async setSearchKeyword(context, payload) {
			await context.commit('setSearchKeyword', payload);
		},
		async setPoliceStationIds(context, payload) {
			await context.commit('setPoliceStationIds', payload);
		},
		async setYears(context, payload) {
			await context.commit('setYears', payload);
		},
		async logout(context) {
			sessionStorage.removeItem('user');
			sessionStorage.removeItem('token');
			await context.commit('setUser', null);
			await context.commit('setToken', "");
		},

		auth(context, payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl + 'api/' + payload.uri, payload.data)
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		},

		post(context, payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl + 'api/' + payload.uri, payload.data, {
					headers: {
						'Authorization': 'Bearer' + ' ' + context.getters.token
					}
				}).then(function (response) {
					resolve(response);
				})
					.catch(function (error) {
						reject(error);
						if (error.response.data.message == "Unauthenticated.") {
							context.dispatch('logout');
							window.location.href = "/#/"
							window.location.reload();
						}
					});
			});
		},

		async error(context, description) {
			await createToast(
				{
					title: "Error",
					description: description || "The given data was invalid.",
				},
				{
					showIcon: true,
					hideProgressBar: true,
					type: "danger",
					position: "top-right",
				}
			);
		},

		async success(context, description) {
			await createToast(
				{
					title: "Success",
					description: description || "Data is successfully subbmitted.",
				},
				{
					showIcon: true,
					hideProgressBar: true,
					type: "success",
					position: "top-right",
				}
			);
		},

		async info(context, description) {
			await createToast(
				{
					title: "Info",
					description: description || "Not enough input data",
				},
				{
					showIcon: true,
					hideProgressBar: true,
					type: "info",
					position: "top-right",
				}
			);
		},
	}
})
