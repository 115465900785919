<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0">Year Wise Audit Report of Muddemal</h4>
        </div>
        <div class="d-flex gap-2 mt-3 mt-md-0">
            <button @click="clearFilter()" class="btn btn-success"><i class="ri-restart-line fs-18 lh-1"></i>
                Reset</button>
            <button @click="download()" class="btn btn-warning"><i class="ri-download-2-line fs-18 lh-1"></i>
                Export</button>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label text-primary">Division</label>
                            <select class="form-select" v-model="division_id" @change="obtainSubDivisions()">
                                <option value="">Select</option>
                                <option v-for="division, key in divisions" :value="division.division_id">
                                    {{ division.division }}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label text-primary">Sub Division</label>
                            <select class="form-select" v-model="sub_division_id" :disabled="!division_id"
                                @change="getFilteredPoliceStations()">
                                <option value="">Select</option>
                                <option v-for="sub_division, key in sub_divisions"
                                    :value="sub_division.sub_division_id">{{ sub_division.sub_division }}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label text-primary">Police Station</label>
                            <search :class="{ 'is-invalid': errors?.police_station_id }"
                                :customClass="{ 'is-invalid': errors?.police_station_id }"
                                :initialize="police_station_id" id="police_station_id" label="police_station"
                                placeholder="Select police station" :data="police_stations"
                                @selected="onPoliceStationSelected">
                            </search>
                            <span v-if="errors?.police_station_id" class="invalid-feedback">
                                {{ errors?.police_station_id[0] }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label text-primary">Year</label>
                            <MultiSelect v-model="selected_years" filter optionLabel="" :options="years"
                                placeholder="Select Year" :maxSelectedLabels="3" class=" "
                                style="height: 35px;width: 100%" @change="getYearWiseAuditReport()" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-body">
                    <div class="table-responsive" style="overflow-y:scroll; height: 55vh;">
                        <table id="download-table" class="table text-nowrap table-hover table-bottom-border mb-0">
                            <thead>
                                <tr style="background-color: #f5f5f5;">
                                    <th rowspan="2" class="text-center">Sl No.</th>
                                    <th class="text-center" rowspan="2">Year</th>
                                    <th colspan="3" class="text-center">Available Properties<span
                                            class="text-primary">[{{
                                                no_of_pf }}]</span></th>
                                    <th colspan="3" class="text-center">Missing Properties<span class="text-primary">[{{
                                        no_of_pnf
                                    }}]</span></th>
                                    <th rowspan="2" class="text-center">Disposed Properties</th>
                                </tr>
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center">Valuable</th>
                                    <th class="text-center">Non-Valuable</th>
                                    <th class="text-center">Total</th>
                                    <th class="text-center">Valuable</th>
                                    <th class="text-center">Non-Valuable</th>
                                    <th class="text-center">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="data, key in report_data">
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td class="text-center">{{ data.year }}</td>
                                    <td class="text-center">
                                        <a href="javascript:void(0)"
                                            @click="getMuddemalsByYear(data.year, true, 'Valuable', false)"
                                            data-bs-toggle="modal" data-bs-target="#muddemalModal">{{
                                                data.found_valuable }}</a>
                                    </td>
                                    <td class="text-center">
                                        <a href="javascript:void(0)"
                                            @click="getMuddemalsByYear(data.year, true, 'Non-Valuable', false)"
                                            data-bs-toggle="modal" data-bs-target="#muddemalModal">{{
                                                data.found_non_valuable }}</a>
                                    </td>
                                    <td class="text-center">{{ data.found_valuable + data.found_non_valuable }}</td>
                                    <td class="text-center">
                                        <a href="javascript:void(0)"
                                            @click="getMuddemalsByYear(data.year, false, 'Valuable', false)"
                                            data-bs-toggle="modal" data-bs-target="#muddemalModal">{{
                                                data.not_found_valuable }}</a>
                                    </td>
                                    <td class="text-center">
                                        <a href="javascript:void(0)"
                                            @click="getMuddemalsByYear(data.year, false, 'Non-Valuable', false)"
                                            data-bs-toggle="modal" data-bs-target="#muddemalModal">{{
                                                data.not_found_non_valuable }}</a>
                                    </td>
                                    <td class="text-center">{{ data.not_found_valuable + data.not_found_non_valuable }}
                                    </td>
                                    <td class="text-center">
                                        <a href="javascript:void(0)"
                                            @click="getMuddemalsByYear(data.year, '', '', true)" data-bs-toggle="modal"
                                            data-bs-target="#muddemalModal">{{
                                                data.found_disposed }}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-center fw-bold">Total</td>
                                    <td class="text-center fw-bold">{{ totals.found_valuable }}</td>
                                    <td class="text-center fw-bold">{{ totals.found_non_valuable }}</td>
                                    <td class="text-center fw-bold">{{ totals.found_total }}</td>
                                    <td class="text-center fw-bold">{{ totals.not_found_valuable }}</td>
                                    <td class="text-center fw-bold">{{ totals.not_found_non_valuable }}</td>
                                    <td class="text-center fw-bold">{{ totals.not_found_total }}</td>
                                    <td class="text-center fw-bold">{{ totals.found_disposed }}</td>

                                </tr>
                            </tbody>
                        </table>
                        <i v-show="no_pf_indication != 0 | no_valuable_or_non_valuable != 0 | no_dispose_indication != 0"
                            class="small text-danger">Note:</i>
                        <ul class="small text-danger">
                            <li v-show="no_pf_indication != 0">
                                <i style="display: block;">{{ no_pf_indication }} properties have not been indicated as
                                    available or unavailable.</i>
                            </li>
                            <li v-show="no_valuable_or_non_valuable != 0">
                                <i style="display: block;">{{ no_valuable_or_non_valuable }} properties have not been
                                    indicated as valuable or non valuable.</i>
                            </li>
                            <li v-show="no_dispose_indication != 0">
                                <i>{{ no_pf_indication }} properties have not been indicated as disposed or not
                                    disposed.</i>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card-footer">
                </div>
            </div>
        </div>
    </div>
    <!-- The Modal -->
    <div class="modal" id="muddemalModal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Muddemal Details</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="table-responsive" style="overflow-y: scroll; height: 55vh;">
                        <table class="table text-nowrap table-hover table-bottom-border mb-0">
                            <thead class="sticky-header">
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center">SL No.</th>
                                    <th>Year</th>
                                    <th>CC/SC Number</th>
                                    <th>PF Date</th>
                                    <th>PF Number</th>
                                    <th>Police Station</th>
                                    <th class="text-nowrap"> Seized Property Details</th>
                                    <th>Siezed Property Available</th>
                                    <th>Siezed Property Valuable ?</th>
                                    <th>Disposed</th>
                                    <th>Property Disposed Reason</th>
                                    <th>Crime No</th>
                                    <th>Section of Law</th>
                                    <th>Investigation Officer</th>
                                    <th>Holding Officer</th>
                                    <th>Seized From Whom</th>
                                    <th>PR No.</th>
                                    <th>PR Date</th>
                                    <th>CC No/ SC No</th>
                                    <th>Case Current Stage</th>
                                    <th>Division</th>
                                    <th>Sub Division</th>
                                    <!-- <th class="text-center">Actions</th> -->
                                </tr>
                            </thead>
                            <tbody v-for="muddemal, key in muddemals" :key="key">
                                <tr>
                                    <td class="text-center">{{ this.meta.from + key }}</td>
                                    <td>{{ muddemal.year }}</td>
                                    <td>{{ muddemal.cc_sc_no }}</td>
                                    <td>{{ muddemal.pf_date }}</td>
                                    <td>{{ muddemal.pf_number }}</td>
                                    <td>{{ muddemal.police_Station?.police_station }}</td>
                                    <td>
                                        {{ muddemal.property_description }}
                                    </td>
                                    <td class="text-center">
                                        <span v-if="muddemal.siezed_property_available == 1">
                                            Yes
                                        </span>
                                        <span v-else-if="muddemal.siezed_property_available == 0">
                                            No
                                        </span>
                                        <span v-else>
                                            -
                                        </span>
                                    </td>
                                    <td class="text-center">{{ muddemal.valuable_non_valuable }}</td>
                                    <td class="text-center">
                                        <span v-if="muddemal.disposed == 1">
                                            Yes
                                        </span>
                                        <span v-else-if="muddemal.disposed == 0">
                                            No
                                        </span>
                                        <span v-else>
                                            -
                                        </span>
                                    </td>
                                    <td>
                                        {{ muddemal?.property_disposal_stage?.property_disposal_stage }}
                                    </td>
                                    <td>{{ muddemal.crime_no_sec }}</td>
                                    <td>{{ muddemal.section_of_law }}</td>
                                    <td>{{ muddemal.investigation_officer }}</td>
                                    <td>{{ muddemal.holding_officer }}</td>
                                    <td>{{ muddemal.siezed_from_whom }}</td>
                                    <td> {{ muddemal.pr_no }} </td>
                                    <td> {{ muddemal.pr_date }} </td>
                                    <td> {{ muddemal.cc_sc_no }} </td>
                                    <td> {{ muddemal.case_stage?.case_stage }} </td>
                                    <td> {{ muddemal.division?.division }} </td>
                                    <td> {{ muddemal.sub_division?.sub_division }} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer d-flex justify-content-between">
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                        @pagechanged="onPageChange" />
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as XLSX from 'xlsx';
import MultiSelect from 'primevue/multiselect';
let Search = require("@/components/Search.vue").default;
import Pagination from "@/components/Pagination.vue";
export default {
    components: {
        Search, MultiSelect, Pagination
    },
    name: "Users",
    data() {
        return {
            meta: {
                search: '',
                order_by: "asc",
                // keyword: "user_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
            },
            pagination_data: {},
            divisions: [],
            sub_divisions: [],
            police_stations: [],
            years: [],
            selected_years: [],
            report_data: [],
            division_id: '',
            sub_division_id: '',
            police_station_id: '',
            totals: {
                found_valuable: 0,
                found_non_valuable: 0,
                found_total: 0,
                not_found_valuable: 0,
                not_found_non_valuable: 0,
                not_found_total: 0,
                found_disposed: 0,
                found_not_disposed: 0,
            },
            no_of_pf: 0,
            no_of_pnf: 0,
            no_pf_indication: 0,
            no_dispose_indication: 0,
            no_valuable_or_non_valuable: 0,
            muddemals: []
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.getDivisions();
            vm.getFilteredPoliceStations();
            vm.getMuddemalYears();
        });
    },
    mounted() {
    },
    methods: {
        getDivisions() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getDivisions" };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.divisions = response.data.data;
                    vm.getYearWiseAuditReport();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        obtainSubDivisions() {
            if (this.division_id == '') {
                this.sub_division_id = '';
                this.police_station_id = '';
                this.getYearWiseAuditReport();
                return;
            }
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "obtainSubDivisions", data: { division_id: this.division_id } };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.sub_divisions = response.data.data;
                    vm.getYearWiseAuditReport();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getFilteredPoliceStations() {
            this.police_station_id = '';
            let data = {
                division_id: this.division_id,
                sub_division_id: this.sub_division_id
            }
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getFilteredPoliceStations", data: data };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.police_stations = response.data.data;
                    vm.getYearWiseAuditReport();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        onPoliceStationSelected(police_station_id) {
            this.police_station_id = police_station_id;
            this.getMuddemalYears();
            this.getYearWiseAuditReport();
        },
        getMuddemalYears() {
            let vm = this;
            let data = {
                division_id: this.division_id,
                sub_division_id: this.sub_division_id,
                police_station_id: this.police_station_id
            }
            let loader = vm.$loading.show();
            let uri = { uri: "getMuddemalYears", data: data };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.years = response.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getYearWiseAuditReport() {
            let selected_years = [];
            this.selected_years.forEach(ele => {
                selected_years.push(ele);
            });
            let data = {
                division_id: this.division_id,
                sub_division_id: this.sub_division_id,
                police_station_id: this.police_station_id,
                years: selected_years
            }

            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getYearWiseAuditReport', data: data })
                .then(response => {
                    loader.hide();
                    this.report_data = response.data.data;
                    this.no_of_pf = response.data.no_of_pf;
                    this.no_of_pnf = response.data.no_of_pnf;
                    this.no_pf_indication = response.data.no_pf_indication;
                    this.no_valuable_or_non_valuable = response.data.no_valuable_or_non_valuable;
                    this.calculateTotals();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },
        calculateTotals() {
            this.totals.found_valuable = 0;
            this.totals.found_non_valuable = 0;
            this.totals.found_total = 0;
            this.totals.not_found_valuable = 0;
            this.totals.not_found_non_valuable = 0;
            this.totals.not_found_total = 0;
            this.totals.found_disposed = 0;
            this.totals.found_not_disposed = 0;
            this.totals.not_found_disposed = 0;
            this.totals.not_found_not_disposed = 0;
            this.report_data.forEach(ele => {
                this.totals.found_valuable += ele.found_valuable;
                this.totals.found_non_valuable += ele.found_non_valuable;
                this.totals.not_found_valuable += ele.not_found_valuable;
                this.totals.not_found_non_valuable += ele.not_found_non_valuable;
                this.totals.found_disposed += ele.found_disposed;
                this.totals.found_not_disposed += ele.found_not_disposed;
            });
            this.totals.found_total = this.totals.found_valuable + this.totals.found_non_valuable;
            this.totals.not_found_total = this.totals.not_found_valuable + this.totals.not_found_non_valuable;
        },

        getMuddemalsByYear(year, is_available, is_valuable, is_disposed, page_type) {
            if (page_type != 'old') {
                this.meta.page = 1;
            }
            this.pagination_data = {
                year: year,
                is_available: is_available,
                is_valuable: is_valuable,
                is_disposed: is_disposed,
                ...this.meta
            };
            if (this.division_id != '') {
                this.pagination_data['division_id'] = this.division_id;
            }
            if (this.sub_division_id != '') {
                this.pagination_data['sub_division_id'] = this.sub_division_id;
            }
            if (this.police_station_id != '') {
                this.pagination_data['police_station_id'] = this.police_station_id;
            }

            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getMuddemalsByYear', data: this.pagination_data })
                .then(response => {
                    loader.hide();
                    this.muddemals = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        onPageChange(page) {
            this.meta.page = page;
            this.getMuddemalsByYear(this.pagination_data.year, this.pagination_data.is_available, this.pagination_data.is_valuable, this.pagination_data.is_disposed, 'old');
        },

        clearFilter() {
            this.division_id = '';
            this.sub_division_id = '';
            this.selected_police_stations = [];
            this.selected_years = [];
            this.getYearWiseAuditReport();
            this.getFilteredPoliceStations();
        },

        download() {
            // Get the table element
            const table = document.getElementById('download-table');

            // Remove all <a> tags to disable hyperlinks
            const links = table.getElementsByTagName('a');
            for (let i = links.length - 1; i >= 0; i--) {
                const link = links[i];
                const textNode = document.createTextNode(link.textContent);
                link.parentNode.replaceChild(textNode, link);
            }

            // Convert the table to a worksheet
            const worksheet = XLSX.utils.table_to_sheet(table);

            // Create a new workbook and append the worksheet
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Export the workbook to an Excel file
            XLSX.writeFile(workbook, 'Year_Wise_Audit_Report_of_Muddemal.xlsx');
        }
    }

}
</script>
<style scoped>
.card-one {
    border-width: 0px;
}

@media only screen and (max-width: 767px) {
    .gap-2 {
        margin-bottom: 0.5rem;
    }
}

.table-responsive {
    overflow-x: auto;
    overflow-y: auto;
    max-height: 400px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    min-width: 50px;
}
</style>