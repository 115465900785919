<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0"><router-link to="/muddemal" type="button"><i
                        class="ri-arrow-left-line menu_icon text-dark fs-18 lh-1 me-2"></i></router-link>View Muddemal
            </h4>
        </div>
        <div class="d-flex gap-2 mt-3 mt-md-0">
            <router-link :to="'/muddemal/' + form?.muddemal_id + '/edit'" type="button" class="btn btn-success"><i
                    class="ri-edit-line fs-18 lh-1 me-1"></i>Edit Muddemal</router-link>
            <router-link to="/muddemal" type="button" class="btn btn-primary"><i
                    class="ri-menu-line fs-18 lh-1 me-1"></i>Muddemals</router-link>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-md-12">
            <div class="card card-one mb-4">
                <!-- <div class="card-header">
                    <h6 class="card-title">PF Details</h6>
                </div> -->
                <div class="card-body">
                    <h5 class="mb-4">PF Details</h5>
                    <div class="mb-3">
                        <div class="row g-2 align-items-center">
                            <div class="col-md-3">
                                <h6>PF Number</h6>
                                <p>{{ form.pf_number }}</p>
                            </div>
                            <div class="col-md-3">
                                <h6>PF Date</h6>
                                <p>{{ form.pf_date }}</p>
                            </div>
                            <div class="col-md-3">
                                <h6>PF Year</h6>
                                <p>{{ form.year }}</p>
                            </div>
                            <div class="col-md-3">
                                <h6>Crime No & U/s</h6>
                                <p>{{ form.crime_no_sec }}</p>
                            </div>
                            <div class="col-md-3">
                                <h6>Investigation Officer</h6>
                                <p>{{ form.investigation_officer }}</p>
                            </div>
                            <div class="col-md-3">
                                <h6>Siezed From Whom</h6>
                                <p>{{ form.siezed_from_whom }}</p>
                            </div>
                            <div class="col-md-3">
                                <h6>Siezed From Place</h6>
                                <p>{{ form.siezed_from_place }}</p>
                            </div>
                            <div class="col-md-3">
                                <h6>CC No/ SC No</h6>
                                <p>{{ form.cc_sc_no }}</p>
                            </div>
                            <div class="col-md-3">
                                <h6>Present Stage of the Case</h6>
                                <p>{{ form.case_stage?.case_stage }}</p>
                            </div>
                            <div class="col-md-3">
                                <h6>PR No.</h6>
                                <p>{{ form.pr_no }}</p>
                            </div>
                            <div class="col-md-3">
                                <h6>PR Date</h6>
                                <p>{{ form.pr_date }}</p>
                            </div>
                            <div class="col-md-3">
                                <h6>Holding Officer</h6>
                                <p>{{ form.holding_officer }}</p>
                            </div>
                            <div class="col-md-3">
                                <h6>Police Station</h6>
                                <p>{{ form.police_Station?.police_station }}</p>
                            </div>
                            <div class="col-md-3">
                                <h6>Division</h6>
                                <p>{{ form.police_Station?.division?.division }}</p>
                            </div>
                            <div class="col-md-3">
                                <h6>Sub Division</h6>
                                <p>{{ form.police_Station?.sub_division?.sub_division }}</p>
                            </div>
                        </div>
                    </div>
                    <h5 class="mb-2"> Siezed Property Details</h5>
                    <div class="table-responsive">
                        <table
                            class="table text-nowrap table-striped table-bordered table-hover table-bottom-border mb-4">
                            <thead>
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center">QR Code</th>
                                    <th class="text-center">Sl No.</th>
                                    <th>Property Type</th>
                                    <th>Property Description</th>
                                    <th>Kilogram</th>
                                    <th>Gram</th>
                                    <th>Seized Property Available</th>
                                    <th>Remarks If Property Not Available</th>
                                    <th>Valuable / Non Valuable</th>
                                    <th>Property Value</th>
                                    <th>Property Disposed</th>
                                    <th>Disposed Date</th>
                                    <th>Property Disposed Reason</th>
                                    <th>Incharge Officer</th>
                                    <th>If Any Remarks By Incharge Officer</th>
                                    <th>Auction Value</th>
                                    <th>Auction Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="property, key in properties" :key="key">
                                    <td>
                                        <a href="javascript:void(0)" @click="openQrCodeModal(property.qr_code)">
                                            <img :src="property.qr_code" height="50" />
                                        </a>
                                    </td>
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td>{{ property.property_type.property_type }}</td>
                                    <td>{{ property.property_description }}</td>
                                    <td>{{ property.kilogram }}</td>
                                    <td>{{ property.grams }}</td>
                                    <td>{{ property.siezed_property_available == 1 ? 'Yes' : 'No' }}</td>
                                    <td>{{ property.remarks_if_property_not_available }}</td>
                                    <td>{{ property.valuable_non_valuable }}</td>
                                    <td>{{ property.property_value }}</td>
                                    <td>{{ property.disposed == 1 ? 'Yes' : 'No' }}</td>
                                    <td>{{ property.disposed_date }}</td>
                                    <td>{{ property.property_disposal_stage?.property_disposal_stage }}</td>
                                    <td>{{ property.officer_incharge }}</td>
                                    <td>{{ property.instructions_by_officer }}</td>
                                    <td>{{ property.auction_sold_value }}</td>
                                    <td>{{ property.auction_sold_date }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
        <div class="modal-content" @click.stop>
            <h3 style="margin-right: auto;">QR Code</h3>
            <span class="close-btn" @click="closeModal">&times;</span>
            <!-- <img :src="selectedQrCode" alt="QR Code" style="max-width: 100%; height: auto;"> -->
            <div class="modal-body mb-4">
                <img :src="selectedQrCode" alt="QR Code" style="max-width: 100%; height: auto;">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary me-2" @click="closeModal">Close</button>
                <button type="button" class="btn btn-success" @click="downloadQrCode(selectedQrCode)">Download</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "MuddemalView",
    data() {
        return {
            form: [],
            properties: [],
            showModal: false,  
            selectedQrCode: null 
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            let uri = { uri: "getMuddemal", data: { muddemal_id: to.params.muddemal_id } };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    vm.form = response.data.data;
                    vm.properties = response.data.data.properties;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        });
    },
    methods: {
        openQrCodeModal(qrCode) {
            this.selectedQrCode = qrCode;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        downloadQrCode(selectedQrCode) 
        {
            const link = document.createElement('a');
            link.href = selectedQrCode;
            link.download = 'qr_code_image.jpg';
            link.click();
        },
    },
};
</script>
<style scoped>
p {
    color: #6e7985;
}
.menu_icon {
    font-size: 20px;
    opacity: 0.85;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    text-align: center;
    position: relative;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    z-index: 1001;
    background: transparent;
    border: none;
}

.close-btn:hover {
    color: red;
}
</style>