<template>
    <div class="page-sign d-block py-0">
        <div class="row g-0">
            <div class="col-md-7 col-lg-5 col-xl-6 col-wrapper">
                <div class="card card-sign">
                    <div class="card-header">
                        <div class="row mb-2 gap-2">
                            <div class="col-2">
                                <img src="assets/img/log11.png" class="img" width="50" height="50" />
                            </div>
                            <div class="col-8">
                                <a href="#" class="text-center h3 header-logo mb-0">Mudde Maal</a>
                                <p class="card-text fs-12">Portal Muddemaal Records</p>
                            </div>
                        </div>
                        <h4 class="card-title1 fw-700 text-dark">Login to your account!</h4>
                    </div>
                    <div class="card-body">
                        <form class="mb-4" @submit.prevent="sendOtp()" v-if="!otp_status">
                            <label class="mb-0 label_phone">Phone Number</label>
                            <div class="input-group border-bottom">
                                <div class="input-group-text border-0 bg-white">+91-</div>
                                <input
                                    type="text"
                                    class="form-control border-0 fs-18"
                                    :class="{ 'is-invalid': errors.mobile_no }"
                                    id="autoSizingInputGroup"
                                    ref="mobile_no"
                                    placeholder="O O O O O O O O O O "
                                    v-model="user.mobile_no"
                                    :disabled="otp_status"
                                />
                                <div class="input-group-text border-0 bg-white"><img class="img_flag" src="assets/img/flag.png" width="100" /></div>
                                <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                            </div>
                            <div class="float-end mb-4 mt-2"><a class="fs-15" href="javascript:void(0)" @click="sendOtp()" style="color: #6e7985;">REQUEST OTP</a></div>
                        </form>
                        <div v-if="otp_status">
                            <div class="mb-4">
                                <label class="mb-0 label_phone">Phone Number</label>
                            <div class="input-group border-bottom1">
                                <div class="input-group-text border-0 bg-white">+91-</div>
                                <input
                                    type="text"
                                    class="form-control border-0 fs-18"
                                    :class="{ 'is-invalid': errors.mobile_no }"
                                    id="autoSizingInputGroup"
                                    ref="mobile_no"
                                    placeholder="O O O O O O O O O O "
                                    v-model="user.mobile_no"
                                    :disabled="otp_status"
                                />
                                <div class="input-group-text border-0 bg-white"><img class="img_flag" src="assets/img/flag.png" width="100" /></div>
                                <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                            </div>
                            </div>
                            <div class="mb-4">
                                <label class="label_phone d-flex justify-content-between mb-0">OTP</label>
                                <div class="inputs d-flex flex-row justify-content-center my-2">
                                    <v-otp-input
                                        ref="otpInput"
                                        input-classes="otp-input m-1 text-center form-control inputotp"
                                        :conditionalClass="p-otp"
                                        separator=""
                                        inputType="letter-numeric"
                                        :num-inputs="6"
                                        v-model:value="p_otp1"
                                        :should-auto-focus="true"
                                        :should-focus-order="true"
                                        @on-change="handleOnChange"
                                        @on-complete="verifyOtp()"
                                    />
                                </div>

                                <div class="float-end mb-4">
                                    <a href="javascrit:void(0)" @click="resendOtp()" style="color: #6e7985;">
                                        <span><i class="h4 ri-restart-line me-2" style="color: black;"></i></span>RESEND THE OTP
                                    </a>
                                </div>
                            </div>
                            <button class="btn btn-primary btn-sign" @click="verifyOtp()">Login To Continue</button>
                        </div>
                    </div>
                    <img src="assets/img/bg3.png" class="img2" />
                </div>
            </div>
            <div class="col d-none d-lg-block" style="position: relative;"><img src="assets/img/office.png" class="auth-img" alt="" /></div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Login",
        data() {
            return {
                user: {
                    mobile_no: "",
                    otp: "",
                },
                errors: [],
                otp_status: false,
                p_otp: {
                    first: "",
                    second: "",
                    third: "",
                    fourth: "",
                    fifth: "",
                    sixth: "",
                },
                p_otp1: "",
            };
        },
        mounted() {
            this.$refs.mobile_no.focus();
        },

        methods: {
            focusInput(e) {
                console.log("e", e);
                console.log(this.p_otp);
                const input = e.target;
                let value = input.value;
                let isValidInput = value.match(/[0-9a-z]/gi);
                input.value = "";
                input.value = isValidInput ? value[0] : "";
                let fieldIndex = input.dataset.index;
                console.log("fieldIndex", fieldIndex);
                // if (fieldIndex < inputs.length - 1 && isValidInput) {
                //     input.nextElementSibling.focus();
                // }
            },
            sendOtp() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("auth", { uri: "sendOtp", data: vm.user })
                    .then(function () {
                        vm.otp_status = true;
                        loader.hide();
                        vm.errors = [];
                        // vm.$refs.otp.focus();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            resendOtp() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("auth", { uri: "resendOtp", data: vm.user })
                    .then(function () {
                        vm.status = true;
                        loader.hide();
                        vm.errors = [];
                        // vm.$refs.otp.focus();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            verifyOtp() {
                let vm = this;
                let otp = [this.p_otp.first, this.p_otp.second, this.p_otp.third, this.p_otp.fourth, this.p_otp.fifth, this.p_otp.sixth];
                this.user.otp = otp.join("");
                console.log("otp", vm.p_otp1);
                vm.user.otp = vm.p_otp1;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("auth", { uri: "verifyOtp", data: vm.user })
                    .then(function (response) {
                        loader.hide();
                        vm.otp_status = false;
                        vm.$store.dispatch("setUser", response.data.user);
                        vm.$store.dispatch("setToken", response.data.token);
                        // vm.$store.dispatch("setPermissions", response.data.permissions);
                        vm.$router.push("/muddemal");
                        // location.reload()
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
        },
    };
</script>
<style scoped>
    .fw-700 {
        font-weight: 700;
    }

    .img_flag {
        height: 20px;
        width: 25px;
    }

    .auth-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        mix-blend-mode: normal !important;
        opacity: inherit !important;
    }

    .fs-12 {
        font-size: 12px;
    }

    .img2 {
        opacity: 15%;
        width: 163px;
        height: 122px;
        top: 873px;
        left: 21px;
    }

    .kan_text {
        position: absolute;
        left: 40px;
        bottom: 8px;
        /* width:498px */
    }
    .inputotp {
        height: 50px !important;
        width: 50px !important;
        margin: 2px !important;
    }

    input::placeholder {
        font-size: 15px;
        font-weight: 600;
    }

    input:-internal-autofill-selected {
        appearance: menulist-button;
        background-image: none !important;
        background-color: white !important;
        color: fieldtext !important;
    }

    .card-sign .form-control,
    .otp-input {
        font-weight: 500;
        color: #212830;
        height: 50px !important;
        width: 50px;
        margin: 2px;
    }
    .form-control:focus {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
</style>
