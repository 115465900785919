import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VOtpInput from "vue3-otp-input";

import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import 'remixicon/fonts/remixicon.css';

import PrimeVue from 'primevue/config';


const app = createApp(App);
app.use(store);
app.use(router);
app.use(LoadingPlugin);

app.component('v-otp-input', VOtpInput)
app.use(PrimeVue);
app.mount('#app');
