<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0">Property Details Report</h4>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label text-primary">Division</label>
                            <select class="form-select" v-model="division_id" @change="obtainSubDivisions()">
                                <option value="">Select</option>
                                <option v-for="division, key in divisions" :value="division.division_id">
                                    {{ division.division }}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label text-primary">Sub Division</label>
                            <select class="form-select" v-model="sub_division_id" :disabled="!division_id"
                                @change="getFilteredPoliceStations()">
                                <option value="">Select</option>
                                <option v-for="sub_division, key in sub_divisions"
                                    :value="sub_division.sub_division_id">{{ sub_division.sub_division }}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label text-primary">Police Station</label>
                            <search :class="{ 'is-invalid': errors?.police_station_id }"
                                :customClass="{ 'is-invalid': errors?.police_station_id }"
                                :initialize="police_station_id" id="police_station_id" label="police_station"
                                placeholder="Select police station" :data="police_stations"
                                @selected="onPoliceStationSelected" :disabled="!sub_division_id">
                            </search>
                            <span v-if="errors?.police_station_id" class="invalid-feedback">
                                {{ errors?.police_station_id[0] }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label text-primary">Year</label>
                            <MultiSelect v-model="selected_years" filter optionLabel="" :options="years"
                                placeholder="Select Year" :maxSelectedLabels="3" class=" "
                                style="height: 35px;width: 100%" @change="getPropertyDetails()" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-body">
                    <div class="table-responsive" v-if="report_data.length" style="overflow-y:scroll; height: 55vh;">
                        <table class="table text-nowrap table-hover table-bottom-border mb-0">
                            <thead>
                                <tr style="background-color: #f5f5f5;" class="text-center">
                                    <th class="text-center" rowspan="2">SL No</th>
                                    <th class="text-center" rowspan="2">Year</th>
                                    <th :rowspan="checkPropertyType(column.column_name) ? 1 : 2"
                                        :colspan="checkPropertyType(column.column_name) ? 2 : 1"
                                        v-for="column, key in report_data[0].properties" :key="key" class="text-center"
                                        v-html="column.column_name"></th>
                                </tr>
                                <tr style="background-color: #f5f5f5;" class="text-center">
                                    <template v-for="column, key in report_data[0].properties" :key="key">
                                        <th v-if="checkPropertyType(column.column_name)"
                                            style="text-transform: capitalize;">kg</th>
                                        <th v-if="checkPropertyType(column.column_name)"
                                            style="text-transform: capitalize;">grams</th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="record, key in report_data">
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td class="text-center">{{ record.year }}</td>
                                    <template v-for="property, key in record.properties">
                                        <td v-if="!checkPropertyType(property.column_name)" :class="{'text-center':key!='Cash'}">
                                            <span v-if="key == 'Cash'">
                                                <a href="javascript:void(0)"
                                                @click="getPropertyDetailsByType(record.year, property.property_type_id)"
                                                data-bs-toggle="modal" data-bs-target="#muddemalModal">{{ formatToIndianCurrency(property.value)
                                                }}</a>
                                            </span>
                                            <span v-else>
                                                <a href="javascript:void(0)"
                                                @click="getPropertyDetailsByType(record.year, property.property_type_id)"
                                                data-bs-toggle="modal" data-bs-target="#muddemalModal">{{ property.value
                                                }}</a>
                                            </span>                                            
                                        </td>
                                        <td class="text-center" v-if="checkPropertyType(property.column_name)">
                                            <a href="javascript:void(0)"
                                                @click="getPropertyDetailsByType(record.year, property.property_type_id)"
                                                data-bs-toggle="modal" data-bs-target="#muddemalModal">{{
                                                    property.kg_value
                                                }}</a>
                                        </td>
                                        <td class="text-center" v-if="checkPropertyType(property.column_name)">
                                            <a href="javascript:void(0)"
                                                @click="getPropertyDetailsByType(record.year, property.property_type_id)"
                                                data-bs-toggle="modal" data-bs-target="#muddemalModal">{{
                                                    property.gm_value
                                                }}</a>
                                        </td>
                                    </template>
                                </tr>
                                <tr>
                                    <td class="fw-bold text-center" colspan="2">Total</td>
                                    <template v-for="total, key in totals" :key="key">
                                        <td v-if="!checkPropertyType(key)" class="fw-bold text-center">
                                            <span v-if="key=='Cash'">{{ formatToIndianCurrency(total) }}</span>
                                            <span v-else>{{ total }}</span>
                                        </td>
                                        <td v-if="checkPropertyType(key)" class="fw-bold text-center">{{
                                            parseInt(total / 1000) }}</td>
                                        <td v-if="checkPropertyType(key)" class="fw-bold text-center">{{
                                            parseInt(total % 1000) }}</td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                </div>
            </div>
        </div>
    </div>
    <!-- The Modal -->
    <div class="modal" id="muddemalModal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Muddemal Details</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    <div class="table-responsive" style="overflow-y:scroll; height: 65vh;">
                        <table class="table text-nowrap table-bottom-border mb-0">
                            <thead>
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center">SL No.</th>
                                    <th>Year</th>
                                    <th>CC/SC Number</th>
                                    <th>PF Date</th>
                                    <th>PF Number</th>
                                    <th>Police Station</th>
                                    <th class="text-nowrap"> Seized Property Details</th>
                                    <th>Siezed Property Available</th>
                                    <th>Disposed</th>
                                    <th>Property Disposed Reason</th>
                                    <th>Crime No</th>
                                    <th>Section of Law</th>
                                    <th>Investigation Officer</th>
                                    <th>Holding Officer</th>
                                    <th>Seized From Whom</th>
                                    <th>PR No.</th>
                                    <th>PR Date</th>
                                    <th>CC No/ SC No</th>
                                    <th>Case Current Stage</th>
                                    <th>Division</th>
                                    <th>Sub Division</th>
                                    <!-- <th class="text-center">Actions</th> -->
                                </tr>
                            </thead>
                            <tbody v-for="muddemal, key in muddemals" :key="key">
                                <tr>
                                    <td :rowspan="muddemal.rowspan" class="text-center">{{ key + 1 }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.year }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.cc_sc_no }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.pf_date }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.pf_number }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.police_Station?.police_station }}</td>
                                    <td
                                        v-if="muddemal.properties?.length && muddemal?.properties[0]?.property_description">
                                        <span class="text-primary">1. </span>{{
                                            muddemal?.properties[0]?.property_description }}
                                    </td>
                                    <td v-else></td>
                                    <td class="text-center" v-if="muddemal.properties?.length">
                                        <span v-if="muddemal?.properties[0]?.siezed_property_available == 1">
                                            {{ muddemal?.properties[0]?.siezed_property_available == 1 ? 'Yes' : 'No' }}
                                        </span>
                                    </td>
                                    <td v-else></td>
                                    <td class="text-center" v-if="muddemal.properties?.length">
                                        <span v-if="muddemal?.properties[0]?.disposed == 1">
                                            {{ muddemal?.properties[0]?.disposed == 1 ? 'Yes' : 'No' }}
                                        </span>
                                        <span v-else>
                                            {{ muddemal?.properties[0]?.disposed == 0 ? 'No' : 'Yes' }}
                                        </span>
                                    </td>
                                    <td class="text-center" v-else></td>
                                    <td
                                        v-if="muddemal.properties?.length && muddemal?.properties[0].property_disposal_stage">
                                        {{ muddemal?.properties[0]?.property_disposal_stage?.property_disposal_stage }}
                                    </td>
                                    <td class="text-center" v-else></td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.crime_no_sec }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.section_of_law }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.investigation_officer }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.holding_officer }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.siezed_from_whom }}</td>
                                    <td :rowspan="muddemal.rowspan"> {{ muddemal.pr_no }} </td>
                                    <td :rowspan="muddemal.rowspan"> {{ muddemal.pr_date }} </td>
                                    <td :rowspan="muddemal.rowspan"> {{ muddemal.cc_sc_no }} </td>
                                    <td :rowspan="muddemal.rowspan"> {{ muddemal.case_stage?.case_stage }} </td>
                                    <td :rowspan="muddemal.rowspan"> {{ muddemal.division?.division }} </td>
                                    <td :rowspan="muddemal.rowspan"> {{ muddemal.sub_division?.sub_division }} </td>
                                </tr>
                                <tr v-for="property, key in muddemal?.properties?.slice(1)" :key="key">
                                    <td class="hover1"><span class="text-primary">{{ key + 2 }}</span>. {{
                                        property?.property_description }}</td>
                                    <td class="text-center">
                                        <span v-if="property.siezed_property_available == 1">
                                            {{ property.siezed_property_available == 1 ? 'Yes' : 'No' }}
                                        </span>
                                        <span v-else>
                                            {{ property.siezed_property_available == 1 ? 'Yes' : 'No' }}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <span v-if="property.disposed == 1">
                                            {{ property.disposed == 1 ? 'Yes' : 'No' }}
                                        </span>
                                        <span v-else>
                                            {{ property.disposed == 0 ? 'No' : 'Yes' }}
                                        </span>
                                    </td>
                                    <td class="hover1">
                                        {{ property?.property_disposal_stage?.property_disposal_stage }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MultiSelect from 'primevue/multiselect';
let Search = require("@/components/Search.vue").default;
export default {
    components: {
        Search, MultiSelect
    },
    name: "Users",
    data() {
        return {
            divisions: [],
            sub_divisions: [],
            police_stations: [],
            years: [],
            selected_years: [],
            report_data: [],
            division_id: '',
            sub_division_id: '',
            police_station_id: '',
            muddemals: [],
            totals: {},
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.getDivisions();
            vm.getMuddemalYears();
        });
    },
    mounted() {
    },
    methods: {
        getDivisions() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getDivisions" };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.divisions = response.data.data;
                    vm.getPropertyDetails();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        obtainSubDivisions() {
            if(this.division_id == ''){                
                this.sub_division_id = '';
                this.police_station_id = '';
                this.getPropertyDetails();
                return;
            }
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "obtainSubDivisions", data: { division_id: this.division_id } };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.sub_divisions = response.data.data;
                    vm.getPropertyDetails();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getFilteredPoliceStations() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getFilteredPoliceStations", data: { sub_division_id: this.sub_division_id } };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.police_stations = response.data.data;
                    vm.getPropertyDetails();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        onPoliceStationSelected(police_station_id) {
            this.police_station_id = police_station_id;
            this.getMuddemalYears();
            this.getPropertyDetails();
        },
        getMuddemalYears() {
            this.selected_years = [];
            let vm = this;
            let data = {
                division_id: this.division_id,
                sub_division_id: this.sub_division_id,
                police_station_id: this.police_station_id
            }
            let loader = vm.$loading.show();
            let uri = { uri: "getMuddemalYears", data: data };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.years = response.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getPropertyDetails() {
            let selected_years = [];
            this.selected_years.forEach(ele => {
                selected_years.push(ele);
            });
            let data = {
                division_id: this.division_id,
                sub_division_id: this.sub_division_id,
                police_station_id: this.police_station_id,
                years: selected_years
            }

            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getPropertyDetails', data: data })
                .then(response => {
                    loader.hide();
                    this.report_data = response.data;
                    this.calculateTotals();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getPropertyDetailsByType(year, property_type_id) {

            let data = {
                year: year,
                property_type_id: property_type_id,
                division_id: this.division_id,
                sub_division_id: this.sub_division_id,
                police_station_id: this.police_station_id
            }

            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getPropertyDetailsByType', data: data })
                .then(response => {
                    loader.hide();
                    this.muddemals = response.data.data;
                    this.muddemals.map(function (ele) {
                        if (ele.properties?.length) {
                            ele.rowspan = ele.properties.length;
                        }
                    });
                    this.calculateTotals();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        calculateTotals() {
            this.totals = this.totals && {};
            this.report_data.forEach(ele => {
                for (let key in ele.properties) {
                    if (typeof this.totals[key] === 'undefined') {
                        this.totals[key] = 0;
                    }

                    if (ele.properties[key] && typeof ele.properties[key].value === 'number') {
                        this.totals[key] += ele.properties[key].value;
                    }
                }
            });
            for (let key in this.totals) {
                this.totals[key] = parseFloat(this.totals[key].toFixed(4));
            }
        },

        checkPropertyType(property_type) {
            if (property_type == 'Gold' || property_type == 'Silver' || property_type == 'Drugs') {
                return true;
            } else {
                return false;
            }
        },
        formatToIndianCurrency(amount) {
            const number = Number(amount);
            const formatter = new Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: 'INR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            return formatter.format(number);
        }
    }

}
</script>
<style scoped>
.card-one {
    border-width: 0px;
}

th,
td {
    min-width: 55px;
}

@media only screen and (max-width: 767px) {
    .gap-2 {
        margin-bottom: 0.5rem;
    }
}
</style>
