<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0">Profile Update</h4>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-body pt-0">
                    <div class="d-md-flex align-items-center justify-content-between">
                        <div class="flex-fill">
                            <div class="row">
                                <div class="col-9">
                                    <div class="row my-2 g-3">
                                        <div class="col-12 mb-2">
                                            <label class="form-label">User Name</label>
                                            <span class="text-danger">*</span>
                                            <input type="text" class="form-control" :class="{ 'is-invalid': errors?.name }" placeholder="Enter user name" v-model="user.name" />
                                            <span v-if="errors?.name" class="invalid-feedback">{{ errors?.name[0] }}</span>
                                        </div>
                                        <div class="col-6 mb-2">
                                            <label class="form-label">E - Mail</label>
                                            <span class="text-danger">*</span>
                                            <input type="email" class="form-control" :class="{ 'is-invalid': errors?.email }" placeholder="Enter user email" v-model="user.email" />
                                            <span v-if="errors?.email" class="invalid-feedback">{{ errors?.email[0] }}</span>
                                        </div>
                                        <div class="col-6 mb-2">
                                            <label class="form-label">Mobile</label>
                                            <span class="text-danger">*</span>
                                            <input type="text" class="form-control" :class="{ 'is-invalid': errors?.mobile_no }" placeholder="Enter user mobile number..." v-model="user.mobile_no" />
                                            <span v-if="errors?.mobile_no" class="invalid-feedback">{{ errors?.mobile_no[0] }}</span>
                                        </div>
                                        <!-- <div class="col-12">
                                            <label class="form-label">Address</label>
                                            <textarea name="" v-model="user.address" class="form-control" :class="{ 'is-invalid': errors?.address }" id="" cols="30" rows="2"></textarea>
                                            <span v-if="errors?.address" class="invalid-feedback">{{ errors?.address[0] }}</span>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-3" style="display: flex; align-items: center; justify-content: center;">
                                    <div class="d-block align-items-start align-items-sm-center gap-4" style="margin-top:20px;">
                                        <img :src="user.avatar" alt="user-avatar" class="d-block rounded m-auto" height="150" width="150" id="uploadedAvatar" />
                                        <div class="button-wrapper text-center">
                                            <label for="upload" class="btn btn-primary me-2 mt-2" tabindex="0">
                                                <span class="d-none d-sm-block">Upload Photo</span>
                                                <i class="bx bx-upload d-block d-sm-none"></i>
                                                <input type="file" id="upload" class="account-file-input" hidden accept="image/png, image/jpeg" @change="onImageChange($event)" />
                                                <!-- <input type="file" id="upload" class="account-file-input" hidden :class="{'is-invalid': errors.avatar}" @change="onImageChange($event)" accept="image/png, image/jpeg" /> -->
                                            </label>
                                        </div>
                                        <span class="invalid-feedback">{{ }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-12">
                            <button class="btn btn-primary float-end" @click="updateUser()">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Change password modal -->
    <div class="modal fade bd-example-modal-md" tabindex="-1" id="changePasswordModal">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Change Password</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label for="" class="form-label">Current Password</label>
                            <input type="password" class="form-control" v-model="change_password.current_password" />
                        </div>
                        <div class="col-12">
                            <label for="" class="form-label">New Password</label>
                            <input type="password" class="form-control" v-model="change_password.new_password" />
                        </div>
                        <div class="col-12">
                            <label for="" class="form-label">Confirm Password</label>
                            <input type="password" class="form-control" v-model="change_password.confirm_password" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="changePassword()" class="btn btn-primary" data-bs-dismiss="modal">Change</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                user: {
                    user_id: "",
                    name: "",
                    email: "",
                    password: "",
                    mobile_no: "",
                    role: "",
                    address: "",
                    avatar: "/assets/img/user.png",
                },
                errors: [],
                image: "",
                change_password: {
                    current_password: "",
                    new_password: "",
                    confirm_password: "",
                },
            };
        },

        beforeRouteEnter(to, form, next) {
            next((vm) => {
                vm.user.user_id = vm.$store.getters.user.user_id;
                vm.getUser();
            });
        },

        methods: {
            updateUser() {
                let data = new FormData();
                data.append("user_id", this.user.user_id);
                data.append("name", this.user.name);
                data.append("email", this.user.email);
                data.append("mobile_no", this.user.mobile_no);
                // data.append("address", this.user.address);
                data.append("avatar", this.image);

                let vm = this;
                let loader = this.$loading.show();
                this.$store
                    .dispatch("post", { uri: "updateProfile", data: data })
                    .then((response) => {
                        loader.hide();
                        this.user = response.data.data;
                        vm.$store.dispatch("setUser", response.data.data);
                        this.$store.dispatch("success", response.data.message);
                        this.errors = [];
                    })
                    .catch((error) => {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            onImageChange(e) {
                let vm = this;
                vm.image = e.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(vm.image);
                reader.onload = (e) => {
                    vm.user.avatar = e.target.result;
                };
            },

            getUser() {
                let vm = this;
                let loader = this.$loading.show();
                this.$store
                    .dispatch("post", { uri: "getUser", data: this.user })
                    .then((response) => {
                        loader.hide();
                        this.user = response.data.data;
                    })
                    .catch((error) => {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            changePassword() {
                let vm = this;
                let loader = this.$loading.show();
                this.$store
                    .dispatch("post", { uri: "changePassword", data: this.change_password })
                    .then((response) => {
                        loader.hide();
                        this.discardChangePassword();
                        this.$store.dispatch("success", response.data.message);
                    })
                    .catch((error) => {
                        console.log(error);
                        loader.hide();
                        this.discardChangePassword();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            discardChangePassword() {
                this.change_password = {
                    current_password: "",
                    new_password: "",
                    confirm_password: "",
                };
            },
        },
    };
</script>
