<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0">Dashboard</h4>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-md-4">
            <label class="form-label">Police Station</label>
            <select class="form-select">
                <option selected>Select Police Station</option>
                <option value="1">Adugodi PS</option>
                <option value="2">Belgaum PS</option>
                <option value="3">Beguru PS</option>
                <option value="4">Koramangala PS</option>
                <option value="5">Siddapura PS</option>
                <option value="6">Hulimavu PS</option>
            </select>
        </div>
        <div class="col-md-4">
            <label class="form-label">Year</label>
            <select class="form-select">
                <option selected>Select Year</option>
                <option value="1">2020</option>
                <option value="2">2021</option>
                <option value="3">2022</option>
                <option value="3">2023</option>
                <option value="3">2024</option>
            </select>
        </div>
        <div class="col-xl-12 mb-2">
            <div class="card card-one">
                <div class="card-body pb-3">
                    <!-- <img src="/assets/img/bar_chart.png" class="mb-4" alt=""> -->
                    <div class="card-body pb-0 mb-0">
                        <BarChart
                            :type="'column'"
                            :container="'barchartb'"
                            :title="barchartb.title"
                            :yAxis="barchartb.yAxis"
                            :xAxis="barchartb.xAxis"
                            :series="barchartb.series"
                            :stacking="barchartb.stacking"
                            :labels="barchartb.labels"
                            :xAxisLables="barchartb.xAxisLables"
                            :yAxisLables="barchartb.yAxisLables"
                            :label_font_size="barchartb.label_font_size"
                            :marginTop="barchartb.marginTop"
                            :height="barchartb.height"
                        >
                        </BarChart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BarChart from "@/components/BarChart";
    export default {
        data() {
            return {
                barchartb: {
                    title: "",
                    xAxis: ["Adugodi PS", "Beguru PS", "Belgaum PS", "Koramangala PS", "Siddapura PS", "Hulimavu PS"],
                    xAxisLables: {
                        enabled: true,
                    },
                    // yAxis: ['Civil', 'Mech', 'E&I'],
                    // yAxisLables: true,
                    stacking: "",
                    labels: "",
                    label_font_size: "12px",
                    marginTop: 15,
                    // height: "145%",
                    series: [
                        {
                            name: "Police Stations",
                            data: [406292, 260000, 107000, 68300, 27500, 14500],
                        },
                    ],
                },
            };
        },
        components: {
            BarChart,
        },
        beforeRouteEnter(to, from, next) {
            console.log("ssss-------",from)
            next((vm) => {
                if (from.name == "Login") {
                    vm.$router.go();
                }
            });
        },
        methods: {},
    };
</script>
<style scoped>
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
    }
</style>
