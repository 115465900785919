<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0">Muddemal Year Wise Cases</h4>
        </div>
        <div class="d-flex justify-content-between">
            <select class="form-select me-2" v-model="property_found">
                <option :value="true">Property Found</option>
                <option :value="false">Property Not Found</option>
            </select>
            <button class="btn btn-primary" @click="clearFilter()">Clear</button>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label text-primary">Division</label>
                            <select class="form-select" v-model="division_id" @change="obtainSubDivisions()">
                                <option value="">Select</option>
                                <option v-for="division, key in divisions" :value="division.division_id">
                                    {{ division.division }}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label text-primary">Sub Division</label>
                            <select class="form-select" v-model="sub_division_id" :disabled="!division_id"
                                @change="getFilteredPoliceStations()">
                                <option value="">Select</option>
                                <option v-for="sub_division, key in sub_divisions"
                                    :value="sub_division.sub_division_id">{{ sub_division.sub_division }}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label text-primary">Police Station</label>
                            <search :class="{ 'is-invalid': errors?.police_station_id }"
                                :customClass="{ 'is-invalid': errors?.police_station_id }"
                                :initialize="police_station_id" id="police_station_id" label="police_station"
                                placeholder="Select police station" :data="police_stations"
                                @selected="onPoliceStationSelected" </search>
                                <span v-if="errors?.police_station_id" class="invalid-feedback">
                                    {{ errors?.police_station_id[0] }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label text-primary">Year</label>
                            <MultiSelect v-model="selected_years" filter optionLabel="" :options="years"
                                placeholder="Select Year" :maxSelectedLabels="3" class=" "
                                style="height: 35px;width: 100%" @change="getYearWiseCasesStatus()" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-body">
                    <div class="table-responsive" style="overflow-y:scroll; height: 55vh;">
                        <table class="table text-nowrap table-hover table-bottom-border mb-0" v-if="report_data.length">
                            <thead>
                                <tr style="background-color: #f5f5f5;">
                                    <th rowspan="2" class="text-center">Sl No.</th>
                                    <th class="text-center" rowspan="2">Year</th>
                                    <th :colspan="Object.keys(report_data[0]?.va_pf_case).length" class="text-center">{{
                                        heading_1 }}</th>
                                    <th :colspan="Object.keys(report_data[0]?.va_pf_case).length" class="text-center">{{
                                        heading_2 }}</th>
                                </tr>
                                <tr style="background-color: #f5f5f5;">
                                    <th v-for="column, key in report_data[report_data.length - 1]?.va_pf_case"
                                        :key="key" class="text-center">{{ key }}</th>
                                    <th v-for="column, key in report_data[report_data.length - 1]?.nv_pf_case"
                                        :key="key" class="text-center">{{ key }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="data, key in report_data">
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td class="text-center">{{ data.year }}</td>
                                    <td v-for="p_name in Object.keys(data.va_pf_case)" :p_name="p_name"
                                        class="text-center">
                                        <a href="javascript:void(0)"
                                            @click="getMuddemalsByCaseStage(data.year, data.va_pf_case[p_name].case_stage_id, property_found, 'Valuable')"
                                            data-bs-toggle="modal" data-bs-target="#muddemalModal">{{
                                                data.va_pf_case[p_name].value }}</a>

                                    </td>
                                    <td v-for="p_name in Object.keys(data.nv_pf_case)" :p_name="p_name"
                                        class="text-center">
                                        <a href="javascript:void(0)"
                                            @click="getMuddemalsByCaseStage(data.year, data.va_pf_case[p_name].case_stage_id, property_found, 'Non-Valuable')"
                                            data-bs-toggle="modal" data-bs-target="#muddemalModal">{{
                                            data.nv_pf_case[p_name].value }}</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <i v-show="no_pf_indication != 0 | no_valuable_or_non_valuable != 0 | no_dispose_indication != 0"
                            class="small text-danger">Note:</i>
                        <ul class="small text-danger">
                            <li v-show="no_pf_indication != 0">
                                <i style="display: block;">{{ no_pf_indication }} properties have not been indicated as
                                    available or unavailable.</i>
                            </li>
                            <li v-show="no_valuable_or_non_valuable != 0">
                                <i style="display: block;">{{ no_valuable_or_non_valuable }} properties have not been
                                    indicated as valuable or non valuable.</i>
                            </li>
                            <li v-show="no_dispose_indication != 0">
                                <i>{{ no_pf_indication }} properties have not been indicated as disposed or not
                                    disposed.</i>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card-footer">
                </div>
            </div>
        </div>
    </div>
    <!-- The Modal -->
    <div class="modal" id="muddemalModal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Muddemal Details</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    <div class="table-responsive" style="overflow-y:scroll; height: 65vh;">
                        <table class="table text-nowrap table-bottom-border mb-0">
                            <thead>
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center">SL No.</th>
                                    <th>Year</th>
                                    <th>CC/SC Number</th>
                                    <th>PF Date</th>
                                    <th>PF Number</th>
                                    <th>Police Station</th>
                                    <th class="text-nowrap"> Seized Property Details</th>
                                    <th>Siezed Property Available</th>
                                    <th>Disposed</th>
                                    <th>Property Disposed Reason</th>
                                    <th>Crime No</th>
                                    <th>Section of Law</th>
                                    <th>Investigation Officer</th>
                                    <th>Holding Officer</th>
                                    <th>Seized From Whom</th>
                                    <th>PR No.</th>
                                    <th>PR Date</th>
                                    <th>CC No/ SC No</th>
                                    <th>Case Current Stage</th>
                                    <th>Division</th>
                                    <th>Sub Division</th>
                                </tr>
                            </thead>
                            <tbody v-for="muddemal, key in muddemals" :key="key">
                                <tr>
                                    <td :rowspan="muddemal.rowspan" class="text-center">{{ key + 1 }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.year }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.cc_sc_no }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.pf_date }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.pf_number }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.police_Station?.police_station }}</td>
                                    <td
                                        v-if="muddemal.properties?.length && muddemal?.properties[0]?.property_description">
                                        <span class="text-primary">1. </span>{{
                                            muddemal?.properties[0]?.property_description }}
                                    </td>
                                    <td v-else></td>
                                    <td class="text-center" v-if="muddemal.properties?.length">
                                        <span v-if="muddemal?.properties[0]?.siezed_property_available == 1">
                                            {{ muddemal?.properties[0]?.siezed_property_available == 1 ? 'Yes' : 'No' }}
                                        </span>
                                    </td>
                                    <td v-else></td>
                                    <td class="text-center" v-if="muddemal.properties?.length">
                                        <span v-if="muddemal?.properties[0]?.disposed == 1">
                                            {{ muddemal?.properties[0]?.disposed == 1 ? 'Yes' : 'No' }}
                                        </span>
                                        <span v-else>
                                            {{ muddemal?.properties[0]?.disposed == 0 ? 'No' : 'Yes' }}
                                        </span>
                                    </td>
                                    <td class="text-center" v-else></td>
                                    <td
                                        v-if="muddemal.properties?.length && muddemal?.properties[0].property_disposal_stage">
                                        {{ muddemal?.properties[0]?.property_disposal_stage?.property_disposal_stage }}
                                    </td>
                                    <td class="text-center" v-else></td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.crime_no_sec }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.section_of_law }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.investigation_officer }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.holding_officer }}</td>
                                    <td :rowspan="muddemal.rowspan">{{ muddemal.siezed_from_whom }}</td>
                                    <td :rowspan="muddemal.rowspan"> {{ muddemal.pr_no }} </td>
                                    <td :rowspan="muddemal.rowspan"> {{ muddemal.pr_date }} </td>
                                    <td :rowspan="muddemal.rowspan"> {{ muddemal.cc_sc_no }} </td>
                                    <td :rowspan="muddemal.rowspan"> {{ muddemal.case_stage?.case_stage }} </td>
                                    <td :rowspan="muddemal.rowspan"> {{ muddemal.division?.division }} </td>
                                    <td :rowspan="muddemal.rowspan"> {{ muddemal.sub_division?.sub_division }} </td>
                                </tr>
                                <tr v-for="property, key in muddemal?.properties?.slice(1)" :key="key">
                                    <td class="hover1"><span class="text-primary">{{ key + 2 }}</span>. {{
                                        property?.property_description }}</td>
                                    <td class="text-center">
                                        <span v-if="property.siezed_property_available == 1">
                                            {{ property.siezed_property_available == 1 ? 'Yes' : 'No' }}
                                        </span>
                                        <span v-else>
                                            {{ property.siezed_property_available == 1 ? 'Yes' : 'No' }}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <span v-if="property.disposed == 1">
                                            {{ property.disposed == 1 ? 'Yes' : 'No' }}
                                        </span>
                                        <span v-else>
                                            {{ property.disposed == 0 ? 'No' : 'Yes' }}
                                        </span>
                                    </td>
                                    <td class="hover1">
                                        {{ property?.property_disposal_stage?.property_disposal_stage }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MultiSelect from 'primevue/multiselect';
let Search = require("@/components/Search.vue").default;
export default {
    components: {
        Search, MultiSelect
    },
    name: "Users",
    data() {
        return {
            divisions: [],
            sub_divisions: [],
            police_stations: [],
            years: [],
            selected_years: [],
            report_data: [],
            division_id: '',
            sub_division_id: '',
            police_station_id: '',
            totals: {
                found_valuable: 0,
                found_non_valuable: 0,
                not_found_valuable: 0,
                not_found_non_valuable: 0,
                found_disposed: 0,
                found_not_disposed: 0,
            },
            no_of_pf: 0,
            no_of_pnf: 0,
            no_pf_indication: 0,
            no_dispose_indication: 0,
            no_valuable_or_non_valuable: 0,
            heading_1: '',
            heading_2: '',
            property_found: true,
            uri: 'getYearWiseCasesStatusPF',
            muddemals: []
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.getDivisions();
            vm.getMuddemalYears();
            vm.getFilteredPoliceStations();
        });
    },
    mounted() {
    },
    watch: {
        property_found(val) {
            if (val) {
                this.uri = 'getYearWiseCasesStatusPF';
            } else {
                this.uri = 'getYearWiseCasesStatusPNF';
            }
            this.getYearWiseCasesStatus();
        }
    },
    methods: {
        getDivisions() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getDivisions" };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.divisions = response.data.data;
                    vm.getYearWiseCasesStatus();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        obtainSubDivisions() {
            if (this.division_id == '') {
                this.getYearWiseCasesStatus();
                return;
            }
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "obtainSubDivisions", data: { division_id: this.division_id } };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.sub_divisions = response.data.data;
                    vm.getYearWiseCasesStatus();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getFilteredPoliceStations() {
            let data = {
                division_id: this.division_id,
                sub_division_id: this.sub_division_id
            }
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getFilteredPoliceStations", data: data };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.police_stations = response.data.data;
                    vm.getYearWiseCasesStatus();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        onPoliceStationSelected(police_station_id) {
            this.police_station_id = police_station_id;
            this.getMuddemalYears();
            this.getYearWiseCasesStatus();
        },
        getMuddemalYears() {
            let vm = this;
            let data = {
                division_id: this.division_id,
                sub_division_id: this.sub_division_id,
                police_station_id: this.police_station_id
            }
            let loader = vm.$loading.show();
            let uri = { uri: "getMuddemalYears", data: data };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.years = response.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getYearWiseCasesStatus() {
            let selected_years = [];
            this.selected_years.forEach(ele => {
                selected_years.push(ele);
            });
            let data = {
                division_id: this.division_id,
                sub_division_id: this.sub_division_id,
                police_station_id: this.police_station_id,
                years: selected_years
            }

            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: this.uri, data: data })
                .then(response => {
                    loader.hide();
                    this.report_data = response.data.data;
                    this.no_of_pf = response.data.no_of_pf;
                    this.no_of_pnf = response.data.no_of_pnf;
                    this.no_pf_indication = response.data.no_pf_indication;
                    this.no_valuable_or_non_valuable = response.data.no_valuable_or_non_valuable;
                    this.heading_1 = response.data.heading_1;
                    this.heading_2 = response.data.heading_2;
                    // this.calculateTotals();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },
        calculateTotals() {
        },

        clearFilter() {
            this.division_id = '';
            this.sub_division_id = '';
            this.selected_years = [];
            this.police_station_id = '';
            this.getMuddemalYears = '';
            this.property_found = true;
            this.getYearWiseCasesStatus();
        },

        getMuddemalsByCaseStage(year, case_stage_id, is_available, is_valuable) {

            let data = {
                year: year,
                case_stage_id: case_stage_id,
                is_available: is_available,
                is_valuable: is_valuable
            }

            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getMuddemalsByCaseStage', data: data })
                .then(response => {
                    loader.hide();
                    this.muddemals = response.data.data;
                    this.muddemals.map(function (ele) {
                        if (ele.properties?.length) {
                            ele.rowspan = ele.properties.length;
                        }
                    });
                    this.calculateTotals();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },
    }

}
</script>
<style scoped>
.card-one {
    border-width: 0px;
}

@media only screen and (max-width: 767px) {
    .gap-2 {
        margin-bottom: 0.5rem;
    }
}

.th,
td {
    min-width: 60px;
}
</style>
