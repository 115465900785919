<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0">Police Station</h4>
        </div>
    </div>
    <div class="row g-3 mb-2">
        <div class="col-xl-4">
            <form class="card card-one" @submit.prevent="submitForm()">
                <div class="card-header">
                    <h6 class="card-title" v-if="status">New Police Station</h6>
                    <h6 class="card-title" v-else>Update Police Station </h6>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 mb-2">
                            <label class="form-label">Division</label><span class="text-danger"> *</span>
                            <select class="form-select" :class="{ 'is-invalid': errors.division_id }" @click="getSubDivisions()"
                                v-model="police_station.division_id" ref="division_id">
                                <option value="">Select Division</option>
                                <option v-for="division, key in divisions" :key="key" :value="division.division_id"> {{
                                    division.division }}</option>
                            </select>
                            <span v-if="errors.division_id" class="invalid-feedback">{{ errors.division_id[0] }}</span>
                        </div>
                        <div class="col-md-12 mb-2">
                            <label class="form-label">Sub Division</label><span class="text-danger"> *</span>
                            <select class="form-select" :class="{ 'is-invalid': errors.sub_division_id }"
                                v-model="police_station.sub_division_id">
                                <option value="">Select Sub Division</option>
                                <option v-for="sub_division, key in sub_divisions" :key="key"
                                    :value="sub_division.sub_division_id"> {{ sub_division.sub_division }}</option>
                            </select>
                            <span v-if="errors.sub_division_id" class="invalid-feedback">{{ errors.sub_division_id[0]
                                }}</span>
                        </div>
                        <div class="col-md-12">
                            <label class="form-label">Police Sation</label><span class="text-danger"> *</span>
                            <input type="text" class="form-control" placeholder="Police Sation"
                                :class="{ 'is-invalid': errors.police_station }"
                                v-model="police_station.police_station" />
                            <span v-if="errors.police_station" class="invalid-feedback">{{ errors.police_station[0]
                                }}</span>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-end">
                    <button type="button" class="btn btn-danger me-2" @click="discard()"><i
                            class="ri-prohibited-line me-1"></i>Discard</button>
                    <button type="submit" class="btn btn-primary">
                        <span v-if="status"> <i class="ri-save-line me-1"></i> Submit</span>
                        <span v-else> <i class="ri-save-line me-1"></i> Update </span>
                    </button>
                </div>
            </form>
        </div>
        <div class="col-xl-8">
            <div class="card card-one">
                <div class="card-header">
                    <h6 class="card-title">Police Stations</h6>
                </div>
                <div class="card-body">
                    <input type="text" class="form-control mb-2" id="search" placeholder="Search" v-model="meta.search"
                        @keypress.enter="search()">
                    <div class="table-responsive" style="overflow-y:scroll; height: 55vh;">
                        <table
                            class="table table-striped text-nowrap table-bordered table-hover table-bottom-border mb-0">
                            <thead>
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center">Sl No.</th>
                                    <th @click="sort('division_id')">Division
                                        <span>
                                            <i v-if="meta.keyword == 'division_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'division_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('sub_division_id')">Sub Division
                                        <span>
                                            <i v-if="meta.keyword == 'sub_division_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'sub_division_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('police_station')">Police Station
                                        <span>
                                            <i v-if="meta.keyword == 'police_station' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'police_station' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="police_stations.length == 0">
                                    <td colspan="6" class="text-center">No records found</td>
                                </tr>
                                <tr v-for="police_station, key in police_stations" :key="key">
                                    <td class="text-center">{{ meta.from + key }}</td>
                                    <td>{{ police_station.division.division }}</td>
                                    <td>{{ police_station.sub_division.sub_division }}</td>
                                    <td>{{ police_station.police_station }}</td>
                                    <td class="text-center">
                                        <div class="form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch"
                                                :id="'police_station' + police_station.police_station_id"
                                                :checked="police_station.status" :value="police_station.status"
                                                @change="deletePoliceStation(police_station)" />
                                            <label class="custom-control-label"
                                                :for="'police_station' + police_station.police_station_id"></label>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <nav>
                                            <a href="javascript:void(0)" class="text-primary me-2"
                                                v-if="police_station.status"
                                                @click="editPoliceStation(police_station)"><i
                                                    class="ri-edit-line fs-18 lh-1"></i></a>
                                        </nav>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                        @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            meta: {
                search: "",
                order_by: "asc",
                keyword: "police_station_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                to: 1,
                maxPage: 1,
                trashed: false,
            },
            police_station: {
                police_station_id: "",
                division_id: "",
                sub_division_id: "",
                police_station: ""
            },
            status: true,
            errors: [],
            divisions: [],
            sub_divisions: [],
            police_stations: [],
        };
    },
    mounted() {
        this.$refs.division_id.focus();
        this.index();
        this.getDivisions();
    },
    methods: {
        submitForm() {
            let vm = this;
            if (vm.status) {
                vm.create();
            } else {
                vm.update();
            }
        },
        index() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "paginatePoliceStations", data: vm.meta })
                .then((response) => {
                    vm.police_stations = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.to = response.data.meta.to;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        create() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store
                .dispatch("post", { uri: "addPoliceStation", data: vm.police_station })
                .then((response) => {
                    loader.hide();
                    vm.$store.dispatch("success", "Police Station Added successfully");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        update() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store
                .dispatch("post", { uri: "updatePoliceStation", data: vm.police_station })
                .then((response) => {
                    loader.hide();
                    vm.$store.dispatch("success", "Police Station is successfully updated");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getDivisions() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getDivisions" })
                .then(response => {
                    vm.divisions = response.data.data;
                    // vm.getSubDivisions();
                })
                .catch(function (error) {
                    console.log(error);
                    // vm.errors = error.response.data.errors;
                    // vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getSubDivisions() {            
            let vm = this;
            if(vm.police_station.division_id == ''){
                return;
            }
            let loader = vm.$loading.show();
            vm.$store
                .dispatch("post", { uri: "obtainSubDivisions", data:{division_id: vm.police_station.division_id} })
                .then(response => {
                    loader.hide();
                    vm.sub_divisions = response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        editPoliceStation(police_station) {
            let vm = this;
            vm.$refs.division_id.focus();
            vm.errors = [];
            vm.status = false;
            vm.police_station = police_station;
            vm.getSubDivisions();
        },

        deletePoliceStation(police_station) {
            let vm = this;
            let loader = vm.$loading.show();
            police_station.status = police_station.status == 1 ? 0 : 1;
            vm.$store
                .dispatch("post", { uri: "deletePoliceStation", data: police_station })
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", response.data.message);
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        search() {
            let vm = this;
            vm.meta.page = 1;
            vm.index();
        },

        onPageChange(page) {
            let vm = this;
            vm.meta.page = page;
            vm.index();
        },
        sort(field) {
            let vm = this;
            vm.meta.keyword = field;
            vm.meta.order_by = vm.meta.order_by == "asc" ? "desc" : "asc";
            vm.index();
        },
        discard() {
            let vm = this;
            vm.police_station.division_id = "";
            vm.police_station.sub_division_id = "";
            vm.police_station.police_station = "";
            vm.$refs.division_id.focus();
            vm.errors = [];
            vm.status = true;
            vm.index();
        },
        onPerPageChange() {
            this.meta.page = 1;
            this.index();
        },
    },
};
</script>
<style scoped>
input:focus,
select:focus {
    outline: none;
    box-shadow: 0 0 0 0 transparent;
}
</style>