<template>
    <figure class="barchart-figure mb-0">
        <div :id="container"></div>
    </figure>
</template>

<script>
    var Highcharts = require("highcharts");
    require("highcharts/modules/heatmap")(Highcharts);
	require("highcharts/modules/data")(Highcharts);
    require("highcharts/modules/exporting")(Highcharts);
    require("highcharts/modules/export-data")(Highcharts);
    require("highcharts/modules/accessibility")(Highcharts);
    require("highcharts/highcharts-more")(Highcharts);
    export default {
        name: "Barchart",
        props: {
			container: {
                default: null,
            },
            title: {
                default: null,
            },
            max: {
				default: null,
			},
			series: {
				default: null,
			},
            yAxis: {
				default: null,
			},
            xAxis: {
				default: null,
			},
            type:{
                default:null,
            },
            stacking:{
                default:null,
            },
            labels:{
                default:null,
            },
            xAxisLables:{
                default:null,
            },
            yAxisLables:{
                default:true,
            },
            subtitle:{
                default:null,
            },
            height:{
                default:null,
            },
            marginTop: {
                default: null,
			},
            label_font_size: String,
            legendEnabled:{
                default:true,
            },
            marginBottom:{
                default:null,
            }
             

        },

        watch: {
			series: {
                handler() {
                    this.loadChart();
                },
                deep: true,
            },
            max: {
                handler() {
                    this.loadChart();
                },
                deep: true,
            },
        },

        mounted() {
            this.loadChart();
            // console.log("vm.labels:----",this.labels)
        },

        methods: {
            loadChart() {
                let vm = this;
                Highcharts.chart(vm.container, {
                    credits: {
                        enabled: false,
                    },
                    exporting:{
                        enabled:false,
                    },
                    chart: {
                        type: vm.type,
                        zoomType: 'xy',
                        // plotBorderWidth: 1,
                        // scrollablePlotArea: {
                        //     // minWidth: vm.minWidth,
                        //     scrollPositionX: 1
                        // },
                        height: vm.height,
                        marginTop: vm.marginTop,
                        marginBottom:vm.marginBottom,
                    },
                    title: {
                        text: vm.title,
                        style: {
                            fontSize: vm.label_font_size,
                        }
                    },
                    subtitle:vm.subtitle,
                    xAxis: {
                        categories: vm.xAxis,
                        // min: vm.min,
                        // max: vm.max,
                        // scrollbar: {
                        //     enabled: true,
                        //     showFull: true,
                        // },
                        labels: vm.xAxisLables,
                        tickLength: 0,
                        gridLineWidth: 0,
                        lineWidth: 0
                    },

                    yAxis: {
                        title: {
                                text: null
                            },
                        categories: vm.yAxis,
                        scrollbar: {
                            enabled: true,
                        },
                        style: {
                            fontSize: vm.label_font_size,
                        },
                        min: 0,
                        // labels: {
                        //     overflow: 'justify'
                        // },
                        gridLineWidth: 1,
                        lineWidth: 0,
                        tickPositioner: function() {
                            return vm.yAxis;
                        },
                        labels: {
                            formatter: function(){
                                console.log("lables", vm.labels)
                                if(vm.labels){
                                    return this.value / 100 + '%';
                                }
                                else{
                                    console.log("lables", this.value)
                                    return this.value
                                }
                                
                            },
                            enabled: vm.yAxisLables,
                        }
                        // labels:vm.labels
                    },

                    legend: {
                        enabled: vm.legendEnabled,
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'top',
                        x: 10,
                        y: -18,
                        floating: true,
                        // borderWidth: 1,
                        backgroundColor:Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                        // shadow: true,
                        itemStyle: {
                            // color: 'white',
                            // fontWeight: 'bold',
                            fontSize: '9px'
                        }
                    },
                    plotOptions: {
                        bar: {
                            // borderRadius: '50%',
                            dataLabels: {
                                enabled: true
                            },
                            groupPadding: 0.3
                        },
                        series: {
                            stacking: vm.stacking,
                            // dataLabels: {
                            //     enabled: true
                            // }
                        }
                    },                    
                    series: vm.series,

                });
               
            }
        }
    };
</script>

<style scoped>
    /* .highcharts-figure,
    .highcharts-data-table table {
        min-width: 310px;
        max-width: 800px;
        margin: 1em auto;
    }

    #container {
        height: 400px;
    }

    .highcharts-data-table table {
        font-family: Verdana, sans-serif;
        border-collapse: collapse;
        border: 1px solid #ebebeb;
        margin: 10px auto;
        text-align: center;
        width: 100%;
        max-width: 500px;
    }

    .highcharts-data-table caption {
        padding: 1em 0;
        font-size: 1.2em;
        color: #555;
    }

    .highcharts-data-table th {
        font-weight: 600;
        padding: 0.5em;
    }

    .highcharts-data-table td,
    .highcharts-data-table th,
    .highcharts-data-table caption {
        padding: 0.5em;
    }

    .highcharts-data-table thead tr,
    .highcharts-data-table tr:nth-child(even) {
        background: #f8f8f8;
    }

    .highcharts-data-table tr:hover {
        background: #f1f7ff;
    } */

</style>