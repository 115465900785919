<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0">Download QR Codes</h4>
        </div>
        <div class="d-flex gap-2 mt-3 mt-md-0">
            <div class="align-items-center gap-2 ml-auto">
                <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Search" v-model="meta.search" @keypress.enter="search()" />
            </div>
        </div>
    </div>
    <div class="row g-3 mb-2">
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-striped text-nowrap table-hover table-bottom-border mb-0">
                            <thead>
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center">Sl No.</th>
                                    <th @click="sort('user_id')">
                                        Name
                                        <span>
                                            <i v-if="meta.keyword == 'user_id' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'user_id' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('user_id')">
                                        Mobile No.
                                        <span>
                                            <i v-if="meta.keyword == 'user_id' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'user_id' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('police_station_id')">Police Station
                                        <span>
                                            <i v-if="meta.keyword == 'police_station_id' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'police_station_id' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span></th>
                                    <th @click="sort('date_time')">Date & Time
                                         <span>
                                            <i v-if="meta.keyword == 'date_time' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'date_time' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="result, key in results" :key="key">
                                    <td class="text-center">{{ meta.from + key }}</td>
                                    <td>{{ result.user.name }}</td>
                                    <td>{{ result.user.mobile_no }}</td>
                                    <td>{{ result.police_station }}</td>
                                    <td>{{changeDateFormat(result.date_time)}}</td>
                                    <td class="text-center">
                                        <nav>
                                            <a href="javascript:void(0)" @click="downloadMuddemals(result.file_name)" class="text-primary me-2" title="Download"><i class="ri-download-2-line fs-18 lh-1"></i></a>
                                            <a href="javascript:void(0)" @click="deleteLog(result)" class="text-danger me-2" title="Delete"><i class="ri-delete-bin-6-line fs-18 lh-1"></i></a>
                                        </nav>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    import moment from "moment";
    export default {
        components: {
            Pagination,
        },
        name: "DownloadedQRCodes",
        data() {
            return {
                results: [],
                status: true,
                meta: {
                    search: "",
                    order_by: "desc",
                    keyword: "qr_property_id",
                    per_page: 15,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getUserLogs();
            });
        },
        methods: {
            getUserLogs() {
                let vm = this;
                 let loader = vm.$loading.show();
                let uri = { uri: "paginateQrProperties", data: vm.meta };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.results = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            changeDateFormat(date) {
                if(date){
                    return moment(date).format('YYYY-MM-DD HH:mm')
                    // console.log("date", moment(date))
                    // console.log("date", moment(date, 'YYYY-MM-DD HH:mm:ss').format("yyyy-MM-DD HH:mm"))
                }
            },
            deleteLog(data) {
                let vm = this;
                 let loader = vm.$loading.show();
                if (confirm("Do You want to Delete this Record?..")) {
                    let uri = { uri: "deleteQrProperty", data: data };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            loader.hide();
                            vm.$store.dispatch("success", response.data.message);
                            vm.getUserLogs();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
            downloadMuddemals(file_path) {
                window.open(file_path);
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.getUserLogs();
            },

            onPageChange(page) {
                let vm = this;
                vm.meta.page = page;
                vm.getUserLogs();
            },
            sort(field) {
                let vm = this;
                vm.meta.keyword = field;
                vm.meta.order_by = vm.meta.order_by == "asc" ? "desc" : "asc";
                vm.getUserLogs();
            },
        },
    };
</script>
