<template>

	<div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
		<div>
			<!-- <h4 class="main-title mb-0" v-if="status">
                <router-link to="/muddemal" type="button"><i class="ri-arrow-left-line menu_icon text-dark fs-18 lh-1 me-2"></i></router-link>Create Muddemal
            </h4> -->
			<h4 class="main-title mb-0">
				<router-link to="/muddemal" type="button"><i
						class="ri-arrow-left-line menu_icon text-dark fs-18 lh-1 me-2"></i></router-link> Update
				Muddemal
			</h4>
		</div>
		<div class="d-flex gap-2 mt-3 mt-md-0">
			<router-link to="/muddemal" type="button" class="btn btn-primary"><i
					class="ri-menu-line fs-18 lh-1 me-1"></i>Muddemals</router-link>
		</div>
	</div>
	<div class="row g-3">
		<div class="col-md-12">
			<div class="card mb-4">
				<div class="card-body">
					<div class="mb-2">
						<h5 class="mb-0">Muddemal Input Details</h5>
						<div class="row">
							<div class="col-12">
								<div class="col-12 d-flex flex-row-reverse">
									<div class="d-flex align-items-center">
										<label class="form-check me-3">
											<input type="radio" class="form-check-input" value="CRIME_NO"
												v-model="form.number_type">
											Crime No.
										</label>

										<label class="form-check me-3">
											<input type="radio" class="form-check-input" value="UDR_NO"
												v-model="form.number_type">
											UDR No.
										</label>

										<label class="form-check me-3">
											<input type="radio" class="form-check-input" value="NCR_NO"
												v-model="form.number_type">
											NCR No.
										</label>
										<label class="form-check">
											<input type="radio" class="form-check-input" value="C_MISC"
												v-model="form.number_type">
											C Misc.
										</label>
									</div>
								</div>
								<div class="row my-2 g-3">
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">PF Number
												<span class="text-danger"> * </span>
											</label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('pf_number')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<input type="text" class="form-control" placeholder="PF Number"
											:class="{ 'is-invalid': errors.pf_number }" v-model="form.pf_number"
											ref="pf_number" />
										<span v-if="errors.pf_number" class="invalid-feedback">{{ errors.pf_number[0]
											}}</span>
									</div>
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">PF Date<span class="text-danger"> *</span></label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('pf_date')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<input type="date" class="form-control"
											:class="{ 'is-invalid': errors.pf_date }" v-model="form.pf_date"
											@change="getYearFromDate(form.pf_date)" max="9999-12-31" />
										<span v-if="errors.pf_date" class="invalid-feedback">{{ errors.pf_date[0]
											}}</span>
									</div>
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">PF Year<span class="text-danger"> *</span></label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('year')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<input readonly type="text" class="form-control" placeholder="PF Year"
											:class="{ 'is-invalid': errors.year }" v-model="form.year" />
										<span v-if="errors.year" class="invalid-feedback">{{ errors.year[0] }}</span>
									</div>
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">{{ number_type_label }}<span class="text-danger">
													*</span></label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('crime_no_sec')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<input type="text" class="form-control" :placeholder="number_type_label"
											:class="{ 'is-invalid': errors.crime_no_sec }"
											v-model="form.crime_no_sec" />
										<span v-if="errors.crime_no_sec" class="invalid-feedback">{{
											errors.crime_no_sec[0] }}</span>
									</div>
									<div class="col-3 mb-3">
										<label class="form-label">Section of Law (IPC/BNS)</label>
										<input type="text" class="form-control" placeholder="Section of Law"
											:class="{ 'is-invalid': errors.section_of_law }"
											v-model="form.section_of_law" />
										<span v-if="errors.section_of_law" class="invalid-feedback">{{
											errors.section_of_law[0] }}</span>
									</div>
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">Investigation Officer [Seized Officer]</label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('investigation_officer')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<input type="text" class="form-control" placeholder="Investigation Officer"
											:class="{ 'is-invalid': errors.investigation_officer }"
											v-model="form.investigation_officer" />
										<span v-if="errors.investigation_officer" class="invalid-feedback">{{
											errors.investigation_officer[0] }}</span>
									</div>
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">Seized From Whom</label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('siezed_from_whom')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<input type="text" class="form-control" placeholder="Seized From Whom"
											:class="{ 'is-invalid': errors.siezed_from_whom }"
											v-model="form.siezed_from_whom" />
										<span v-if="errors.siezed_from_whom" class="invalid-feedback">{{
											errors.siezed_from_whom[0] }}</span>
									</div>
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">Seized From Place</label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('siezed_from_place')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<input type="text" class="form-control" placeholder="Seized From Place"
											:class="{ 'is-invalid': errors.siezed_from_place }"
											v-model="form.siezed_from_place" />
										<span v-if="errors.siezed_from_place" class="invalid-feedback">{{
											errors.siezed_from_place[0] }}</span>
									</div>
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">CC No/ SC No</label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('cc_sc_no')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<input type="text" class="form-control" placeholder="CC No/ SC No"
											:class="{ 'is-invalid': errors.cc_sc_no }" v-model="form.cc_sc_no" />
										<span v-if="errors.cc_sc_no" class="invalid-feedback">{{ errors.cc_sc_no[0]
											}}</span>
									</div>
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">Present Stage of the Case</label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('case_stage_id')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<search :class="{ 'is-invalid': errors.case_stage_id }"
											:customClass="{ 'is-invalid': errors.case_stage_id }"
											:initialize="form.case_stage_id" id="case_stage_id" label="case_stage"
											placeholder="Select Case Stage" :data="case_stages"
											@input="case_stage => form.case_stage_id = case_stage">
										</search>
										<span v-if="errors.case_stage_id" class="invalid-feedback">{{
											errors.case_stage_id[0] }}</span>
									</div>
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">PR No.</label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('pr_no')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<input type="text" class="form-control" placeholder="PR No."
											:class="{ 'is-invalid': errors.pr_no }" v-model="form.pr_no" />
										<span v-if="errors.pr_no" class="invalid-feedback">{{ errors.pr_no[0] }}</span>
									</div>
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">PR Date</label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('pr_date')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<input type="date" class="form-control" placeholder="PR Date"
											:class="{ 'is-invalid': errors.pr_date }" v-model="form.pr_date"
											max="9999-12-31" />
										<span v-if="errors.pr_date" class="invalid-feedback">{{ errors.pr_date[0]
											}}</span>
									</div>
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">Holding Officer [Current]</label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('holding_officer')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<input type="text" class="form-control" placeholder="Holding Officer"
											:class="{ 'is-invalid': errors.holding_officer }"
											v-model="form.holding_officer" />
										<span v-if="errors.holding_officer" class="invalid-feedback">{{
											errors.holding_officer[0] }}</span>
									</div>
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">Police Station</label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('police_station_id')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<search :class="{ 'is-invalid': errors.police_station_id }"
											:customClass="{ 'is-invalid': errors.police_station_id }"
											:initialize="form.police_station_id" id="police_station_id"
											label="police_station" placeholder="Select Police Station"
											:data="police_stations"
											@input="police_station => form.police_station_id = police_station"
											@selectsearch="getDivision(form.police_station_id)">
										</search>
										<span v-if="errors.police_station_id" class="invalid-feedback">{{
											errors.police_station_id[0] }}</span>
									</div>
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">Division</label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('division_id')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<search :class="{ 'is-invalid': errors.division_id }"
											:customClass="{ 'is-invalid': errors.division_id }"
											:initialize="form.division_id" id="division_id" label="division"
											placeholder="Select Division" :data="divisions" :disabled="true"
											@input="division => form.division_id = division">
										</search>
										<span v-if="errors.division_id" class="invalid-feedback">{{
											errors.division_id[0] }}</span>
									</div>
									<div class="col-3 mb-3">
										<div class="d-flex justify-content-between">
											<label class="form-label">Sub Division</label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('sub_division_id')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<search :class="{ 'is-invalid': errors.sub_division_id }"
											:customClass="{ 'is-invalid': errors.sub_division_id }"
											:initialize="form.sub_division_id" id="sub_division_id" label="sub_division"
											placeholder="Select sub_division" :data="sub_divisions" :disabled="true"
											@input="sub_division => form.sub_division_id = sub_division">
										</search>
										<span v-if="errors.sub_division_id" class="invalid-feedback">{{
											errors.sub_division_id[0] }}</span>
									</div>
									<div class="col-3 mb-3" v-if="selected_division == 'CCB'">
										<div class="d-flex justify-content-between">
											<label class="form-label">PF Transferred PS</label>
											<i v-if="!status" data-bs-toggle="modal" data-bs-target="#exampleModal"
												@click="getMuddemalFieldHistory('transferred_ps_id')"
												class="ri-history-line fs-18  text-primary"></i>
										</div>
										<search :class="{ 'is-invalid': errors.transferred_ps_id }"
											:customClass="{ 'is-invalid': errors.transferred_ps_id }"
											:initialize="form.transferred_ps_id" id="police_station_id"
											label="police_station" placeholder="Select Police Station"
											:data="all_police_stations"
											@input="police_station => form.transferred_ps_id = police_station">
										</search>
										<span v-if="errors.transferred_ps_id" class="invalid-feedback">{{
											errors.transferred_ps_id[0] }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mb-2">
						<div class="d-flex justify-content-between">
							<div class="d-flex">
								<h5 class="mb-0">Seized Property Details</h5>
								<span class="text-danger">*</span>
								<i class="ri-error-warning-line text-danger mx-1" v-if="errors.properties"></i>
							</div>
							<button v-if="formStatus" class="btn btn-sm btn-success" type="button"
								@click.prevent="addProperty()"><i class="ri-add-line fs-18 lh-1"></i> Add</button>
						</div>

						<div class="row">
							<div class="col-md-12">
								<div class="table-responsive mt-2" style="overflow-y: scroll;">
									<table class="table text-nowrap table-bottom-border mb-4">
										<thead>
											<tr style="background-color: #f5f5f5;">
												<th>Property Type</th>
												<th>Property Description</th>
												<!-- <th>Quantity</th> -->
												<th>Kilogram</th>
												<th>Gram</th>
												<th>Seized Property Available</th>
												<th>Remarks If Property Not Available</th>
												<th>Valuable / Non Valuable</th>
												<th>Property Value</th>
												<th>Property Disposed</th>
												<th>Property Disposed Reason</th>
												<th>Disposed Date</th>
												<th>Incharge Officer</th>
												<th>If Any Remarks By Incharge Officer</th>
												<th>Auction Value</th>
												<th>Auction Date</th>
												<th>Property Photo</th>
												<th class="text-center">Delete</th>
											</tr>
										</thead>
										<tbody>
											<!-- try -->
											<tr v-for="property, key in form.properties" :key="key">
												<td>
													<div class="input-group">
														<select class="form-control" style="width: 120px; flex:none;"
															:class="{ 'is-invalid': property.errors.property_type_id }"
															v-model="property.property_type_id"
															@change="checkPropertyType(property)">
															<option value="">Select Property Type</option>
															<option v-for="property_type, key in property_types"
																:key="key" :value="property_type.property_type_id"> {{
																	property_type.property_type }}</option>
														</select>
														<div class="input-group-append">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.property_type_id }"
																id="basic-addon2"><i v-if="!status"
																	data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'property_type_id')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i></span>
														</div>
													</div>
													<span v-if="property.errors.property_type_id"
														class="invalid-feedback" style="display:block;">{{
															property.errors.property_type_id[0] }}</span>
												</td>
												<td>
													<div class="input-group">
														<input type="text" class="form-control "
															placeholder="Property Description"
															:class="{ 'is-invalid': property.errors.property_description }"
															v-model="property.property_description"
															style="width: 155px;" maxlength="255" />
														<div class="input-group-append">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.property_description }"
																id="basic-addon2"><i v-if="!status"
																	data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'property_description')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i></span>
														</div>
													</div>
													<span v-if="property.errors.property_description"
														class="invalid-feedback" style="display:block;">{{
															property.errors.property_description[0] }}</span>
												</td>
												<!-- <td>
                                                    <input type="text" class="form-control"
                                                        :placeholder="getMeasuringUnit(property)"
                                                        :class="{ 'is-invalid': property.errors.grams }"
                                                        v-model="property.quantity" @keypress="isNumber($event)"
                                                        style="width: 155px;" />
                                                    <span v-if="property.errors.grams" class="invalid-feedback">{{
                                                        property.errors.grams[0] }}</span>
                                                </td> -->
												<td>
													<div :class="property.amount_input_group">
														<input type="text" class="form-control" placeholder="Kilogram"
															:class="{ 'is-invalid': property.errors.kilogram }"
															v-model="property.kilogram" :style="property.amount_width"
															:disabled="property.amount_status"
															@keypress="isNumber($event)" @copy.prevent @paste.prevent />
														<div class="input-group-append"
															:style="property.amount_display">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.kilogram }"
																id="basic-addon2">
																<i v-if="!status" data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'kilogram')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i>
															</span>
														</div>
													</div>
													<span v-if="property.errors.kilogram" class="invalid-feedback"
														style="display:block;">{{ property.errors.kilogram[0] }}</span>
												</td>
												<td>
													<div :class="property.amount_input_group">
														<input type="text" class="form-control" placeholder="Grams"
															:class="{ 'is-invalid': property.errors.grams }"
															v-model="property.grams" :style="property.amount_width"
															:disabled="property.amount_status"
															@keypress="isNumber($event)" @copy.prevent @paste.prevent />
														<div class="input-group-append"
															:style="property.amount_display">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.grams }"
																id="basic-addon2">
																<i v-if="!status" data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'grams')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i>
															</span>
														</div>
													</div>
													<span v-if="property.errors.grams" class="invalid-feedback"
														style="display:block;">{{ property.errors.grams[0] }}</span>
												</td>
												<td>
													<div class="input-group">
														<select class="form-control"
															:class="{ 'is-invalid': property.errors.siezed_property_available }"
															v-model="property.siezed_property_available"
															@change="showColumn('remarks', property.siezed_property_available, property)">
															<option value="1">Yes</option>
															<option value="0">No</option>
														</select>
														<div class="input-group-append">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.siezed_property_available }"
																id="basic-addon2">
																<i v-if="!status" data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'siezed_property_available')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i>
															</span>
														</div>
													</div>
													<span v-if="property.errors.siezed_property_available"
														class="invalid-feedback" style="display:block;">{{
															property.errors.siezed_property_available[0] }}</span>
												</td>
												<td>
													<div :class="property.remarks_input_group">
														<input type="text" class="form-control" placeholder="Remarks"
															:class="{ 'is-invalid': property.errors.remarks_if_property_not_available }"
															v-model="property.remarks_if_property_not_available"
															:disabled="property.remarks_status" />
														<div class="input-group-append"
															:style="property.remarks_display">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.remarks_if_property_not_available }"
																id="basic-addon2">
																<i v-if="!status" data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'remarks_if_property_not_available')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i>
															</span>
														</div>
													</div>
													<span v-if="property.errors.remarks_if_property_not_available"
														class="invalid-feedback" style="display:block;">{{
															property.errors.remarks_if_property_not_available[0] }}</span>
												</td>
												<td>
													<div class="input-group">
														<select class="form-control"
															:class="{ 'is-invalid': property.errors.valuable_non_valuable }"
															v-model="property.valuable_non_valuable"
															@change="showProperty('valuable', property.valuable_non_valuable, property)">
															<option value="">Select Valuable / Non Valuable</option>
															<option value="Valuable">Valuable</option>
															<option value="Non-Valuable">Non-Valuable</option>
														</select>
														<div class="input-group-append">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.valuable_non_valuable }"
																id="basic-addon2">
																<i v-if="!status" data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'valuable_non_valuable')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i>
															</span>
														</div>
													</div>
													<span v-if="property.errors.valuable_non_valuable"
														class="invalid-feedback" style="display:block;">{{
															property.errors.valuable_non_valuable[0] }}</span>
												</td>
												<td>
													<div :class="property.property_value_input_group">
														<input type="number" min="0"
															oninput="validity.valid||(value='');" class="form-control"
															placeholder="Property Value"
															:class="{ 'is-invalid': property.errors.property_value }"
															:style="property.property_value_width"
															v-model="property.property_value"
															:disabled="property.property_value_status" />
														<div class="input-group-append"
															:style="property.property_value_display">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.property_value }"
																id="basic-addon2">
																<i v-if="!status" data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'property_value')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i>
															</span>
														</div>
													</div>
													<span v-if="property.errors.property_value" class="invalid-feedback"
														style="display:block;">{{ property.errors.property_value[0]
														}}</span>
												</td>
												<td>
													<div class="input-group">
														<select class="form-control"
															:class="{ 'is-invalid': property.errors.disposed }"
															v-model="property.disposed"
															@change="getDisposedStatus1('property_disposed', property.disposed, property)">
															<option value="1">Yes</option>
															<option value="0">No</option>
														</select>
														<div class="input-group-append">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.disposed }"
																id="basic-addon2">
																<i v-if="!status" data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'disposed')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i>
															</span>
														</div>
													</div>
													<span v-if="property.errors.disposed" class="invalid-feedback"
														style="display:block;">{{ property.errors.disposed[0] }}</span>
												</td>
												<td>
													<div :class="property.disposed_input_group">
														<select class="form-control"
															v-model="property.property_disposal_stage_id"
															:class="{ 'is-invalid': property.errors.property_disposal_stage_id }"
															:style="property.disposed_width"
															:disabled="property.disposed_status"
															@change="getDisposedStatus('auction', property.property_disposal_stage_id, property)">
															<option value="null">Select Property Disposal</option>
															<option v-for="property_disposal, key in property_disposals"
																:key="key"
																:value="property_disposal.property_disposal_stage_id">
																{{ property_disposal.property_disposal_stage }}</option>
														</select>
														<div class="input-group-append"
															:style="property.disposed_display">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.property_disposal_stage_id }"
																id="basic-addon2">
																<i v-if="!status" data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'property_disposal_stage_id')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i>
															</span>
														</div>
													</div>
													<span v-if="property.errors.property_disposal_stage_id"
														class="invalid-feedback" style="display:block;">{{
															property.errors.property_disposal_stage_id[0] }}</span>
												</td>
												<td>
													<div class="input-group">
														<input type="date" class="form-control"
															:class="{ 'is-invalid': property.errors.disposed_date }"
															v-model="property.disposed_date" max="9999-12-31" />
														<div class="input-group-append">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.disposed_date }"
																id="basic-addon2">
																<i v-if="!status" data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'disposed_date')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i>
															</span>
														</div>
													</div>
													<span v-if="property.errors.disposed_date" class="invalid-feedback"
														style="display:block;">{{ property.errors.disposed_date[0]
														}}</span>
												</td>
												<td>
													<div class="input-group">
														<input type="text" class="form-control"
															placeholder="Incharge Officer"
															:class="{ 'is-invalid': property.errors.officer_incharge }"
															style="width: 124px;" v-model="property.officer_incharge" />
														<div class="input-group-append">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.officer_incharge }"
																id="basic-addon2">
																<i v-if="!status" data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'officer_incharge')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i>
															</span>
														</div>
													</div>
													<span v-if="property.errors.officer_incharge"
														class="invalid-feedback" style="display:block;">{{
															property.errors.officer_incharge[0] }}</span>
												</td>
												<td>
													<div class="input-group">
														<input type="text" class="form-control"
															placeholder="Remarks By Incharge Officer"
															:class="{ 'is-invalid': property.errors.instructions_by_officer }"
															v-model="property.instructions_by_officer" />
														<div class="input-group-append">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.instructions_by_officer }"
																id="basic-addon2">
																<i v-if="!status" data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'instructions_by_officer')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i>
															</span>
														</div>
													</div>
													<span v-if="property.errors.instructions_by_officer"
														class="invalid-feedback" style="display:block;">{{
															property.errors.instructions_by_officer[0] }}</span>
												</td>
												<td>
													<div :class="property.auction_value_input_group">
														<input type="number" class="form-control"
															placeholder="Auction Value"
															oninput="validity.valid||(value='');"
															:style="property.auction_value_width"
															:class="{ 'is-invalid': property.errors.auction_sold_value }"
															v-model="property.auction_sold_value"
															:disabled="property.auction_status" />
														<div class="input-group-append"
															:style="property.auction_value_display">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.auction_sold_value }"
																id="basic-addon2">
																<i v-if="!status" data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'auction_sold_value')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i>
															</span>
														</div>
													</div>
													<span v-if="property.errors.auction_sold_value"
														class="invalid-feedback" style="display:block;">{{
															property.errors.auction_sold_value[0] }}</span>
												</td>
												<td>
													<div :class="property.auction_value_input_group">
														<input type="date" class="form-control"
															:class="{ 'is-invalid': property.errors.auction_sold_date }"
															v-model="property.auction_sold_date" max="9999-12-31"
															:disabled="property.auction_status" />
														<div class="input-group-append"
															:style="property.auction_value_display">
															<span class="input-group-text"
																:class="{ 'is-invalid': property.errors.auction_sold_date }"
																id="basic-addon2">
																<i v-if="!status" data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'auction_sold_date')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i>
															</span>
														</div>

													</div>
													<span v-if="property.errors.auction_sold_date"
														class="invalid-feedback" style="display:block;">{{
															property.errors.auction_sold_date[0] }}</span>
												</td>
												<td>
													<div class="input-group">
														<div class="mb-1">
															<input type="file" class="form-control"
																:class="{ 'is-invalid': property.errors.property_photo }"
																ref="file"
																@change="getPropertyPhoto($event, property)" />

														</div>

														<div class="input-group-append">
															<span class="input-group-text border-left-0"
																:class="{ 'is-invalid': property.errors.property_photo }"
																id="basic-addon2">
																<i v-if="!status" data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	@click="getPropertyFieldHistory(property, 'property_photo')"
																	class="ri-history-line fs-18 lh-1-2 text-primary"></i>
															</span>
														</div>


													</div>
													<div v-if="property.property_photo">
														<a v-if="property?.property_photo_path && !property?.property_photo_path.endsWith('/')"
															:href="property?.property_photo_path" target="_blank">{{
																property.property_photo }}</a>
														<a v-else :href="property?.url" target="_blank">{{
															property.property_photo_name }}</a>
														<a href="javascript:void(0)" class="text-danger mx-2"
															@click="deletePropertyImage(property)"><i
																class="ri-close-circle-line"></i></a>
													</div>
													<span v-if="property.errors.property_photo" class="invalid-feedback"
														style="display:block;">{{ property.errors.property_photo[0]
														}}</span>
												</td>
												<td class="text-center">
													<nav>
														<a href="javascript:void(0)" class="text-danger"
															@click="deleteRow(property, key)"><i
																class="ri-delete-bin-6-line fs-18 lh-1"></i></a>
													</nav>
												</td>
											</tr>
											<!-- end -->


										</tbody>

									</table>
								</div>
								<span class="text-danger small" v-if="errors.properties">{{ errors?.properties[0]
									}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer text-end">
					<router-link v-if="status" to="/muddemal" type="button" class="btn btn-danger me-2"><i
							class="ri-arrow-left-line me-1"></i>Back</router-link>
					<button class="btn btn-primary" type="button" @click="updateForms()">
						<span> <i class="ri-save-line me-1"></i> Update </span>
					</button>
				</div>
			</div>
		</div>
	</div>

	<!-- model -->
	<div class="modal modal-lg fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
		aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">Field History</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="table-responsive mb-2">
						<input v-if="property_history_status" type="email" class="form-control mb-2"
							id="exampleFormControlInput1" placeholder="Search" v-model="history_meta.search"
							@keypress="search()" />

						<input v-else type="email" class="form-control mb-2" id="exampleFormControlInput1"
							placeholder="Search" v-model="muddemal_meta.search" @keypress="search()" />
						<table class="table table-striped text-nowrap table-hover table-bottom-border mb-0">
							<thead>
								<tr style="background-color: #f5f5f5;">
									<th class="text-center">Sl No.</th>
									<th>Updated Value</th>
									<th>Old Value</th>
									<th>Edited User</th>
									<th>Date & Time</th>
								</tr>
							</thead>
							<tbody v-if="property_history_status">
								<tr v-for="value, key in field_history" :key="key">
									<td v-if="property_history_status" class="text-center">{{ history_meta.from + key }}
									</td>
									<td v-else class="text-center">{{ muddemal_meta.from + key }}</td>
									<td> {{ value.new_value }} </td>
									<td> {{ value.old_value }} </td>
									<td> {{ value.edited_by?.name }} </td>
									<td> {{ changeDateFormat(value.edited_date_time) }} </td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr v-for="value, key in muddemal_history" :key="key">
									<td class="text-center">{{ key + 1 }}</td>
									<td> {{ value.new_value }} </td>
									<td> {{ value.old_value }} </td>
									<td> {{ value.edited_by?.name }} </td>
									<td> {{ changeDateFormat(value.edited_date_time) }} </td>
								</tr>
							</tbody>
						</table>
					</div>
					<div v-if="property_history_status" class="card-footer">
						<Pagination :maxPage="history_meta.maxPage" :totalPages="history_meta.lastPage"
							:currentPage="history_meta.page" @pagechanged="onPageChange" />
					</div>
					<div v-else class="card-footer">
						<Pagination :maxPage="muddemal_meta.maxPage" :totalPages="muddemal_meta.lastPage"
							:currentPage="muddemal_meta.page" @pagechanged="onPageChange" />
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import Search from "@/components/Search.vue";
import Pagination from "@/components/Pagination.vue";
import moment from "moment";
export default {
	name: "MuddemaalEdit",
	components: {
		Search, Pagination
	},
	data() {
		return {
			status: true,
			is_disabled: true,
			selected_division: "",
			form: {
				muddemal_id: "",
				pf_number: "",
				pf_date: "",
				year: "",
				crime_no_sec: "",
				number_type: 'CRIME_NO',
				section_of_law: "",
				investigation_officer: "",
				siezed_from_whom: "",
				siezed_from_place: "",
				cc_sc_no: "",
				case_stage_id: "",
				pr_no: "",
				pr_date: "",
				holding_officer: "",
				police_station_id: "",
				sub_division_id: "",
				division_id: "",
				transferred_ps_id: "",
				properties: [],
				deleted_properties: [],
			},
			field_history: [],
			formStatus: true,
			errors: [],
			case_stages: [],
			police_stations: [],
			all_police_stations: [],
			divisions: [],
			sub_divisions: [],
			property_types: [],
			property_disposals: [],
			auction: false,
			years: [],
			history_meta: {
				search: "",
				order_by: "asc",
				keyword: "old_value",
				per_page: 10,
				totalRows: 0,
				page: 1,
				lastPage: 1,
				from: 1,
				maxPage: 1,
				property_id: [],
				field: '',
				muddemal_id: "",
			},

			muddemal_meta: {
				search: "",
				order_by: "asc",
				keyword: "old_value",
				per_page: 10,
				totalRows: 0,
				page: 1,
				lastPage: 1,
				from: 1,
				maxPage: 1,
				field: '',
				muddemal_id: "",
			},
			property: {},
			muddemal_history: [],
			muddemal_history_status: false,
			property_history_status: false,
			number_type_label: 'Crime No.'
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (to.name == "MuddemaalCreate") {
				vm.$refs.pf_number.focus();
				if (!vm.form.properties.length) {
					vm.addProperty();
				}
			} else {
				vm.status = false;
				let uri = { uri: "getMuddemal", data: { muddemal_id: to.params.muddemal_id } };
				vm.$store
					.dispatch("post", uri)
					.then(function (response) {
						vm.form = response.data.data;
						vm.getDivision(vm.form.police_station_id)
						vm.form.deleted_properties = [];
						if (!vm.form.properties.length) {
							vm.addProperty();
						}

						if (vm.form.properties.length) {
							vm.form.properties.filter(function (ele) {
								if (ele.disposed) {
									vm.property_disposed = true;
								}
								if (ele.property_type.property_type == 'Gold' || ele.property_type.property_type == 'Silver' || ele.property_type.property_type == 'Drugs') {
									ele.amount_input_group = 'input-group'
									ele.amount_width = 'width:90px'
									ele.amount_display = 'display:block'
									ele.amount_status = false
								} else {
									ele.amount_input_group = ''
									ele.amount_display = 'display:none'
									ele.amount_width = 'width:135px'
									ele.amount_status = true
									ele.kilogram = ''
									ele.grams = ''
								}
								if (ele.siezed_property_available == 0) {
									ele.remarks_input_group = 'input-group'
									ele.remarks_display = 'display:block'
									ele.remarks_status = false
								} else {
									ele.remarks_input_group = ''
									ele.remarks_display = 'display:none'
									ele.remarks_status = true
								}

								if (ele.valuable_non_valuable == 'Valuable') {
									ele.property_value_input_group = 'input-group'
									ele.property_value_display = 'display:block'
									ele.property_value_width = 'width:90px'
									ele.property_value_status = false
								} else {
									ele.property_value_input_group = ''
									ele.property_value_display = 'display:none'
									ele.property_value_width = 'width:133px'
									ele.property_value_status = true
								}

								if (ele.disposed == 1 || ele.disposed != '') {
									ele.disposed_input_group = 'input-group'
									ele.disposed_display = 'display:block'
									ele.disposed_width = 'width:155px'
									ele.disposed_status = false
								} else {
									ele.disposed_input_group = ''
									ele.disposed_display = 'display:none'
									ele.disposed_width = 'width:200px'
									ele.disposed_status = true
									ele.auction_sold_value = null;
									ele.auction_sold_date = null;
								}
								if (ele.property_disposal_stage_id || ele.disposed != 0 || ele.disposed != '') {
									ele.auction_value_input_group = 'input-group'
									ele.auction_value_display = 'display:block'
									ele.auction_value_width = 'width:80px'
									if (ele.property_disposal_stage_id != null && ele.property_disposal_stage.property_disposal_stage == "Auction") {
										ele.auction_status = false
									} else {
										ele.auction_status = true;
									}

								} else {
									ele.auction_value_input_group = ''
									ele.auction_value_display = 'display:none'
									ele.auction_value_width = 'width:125px'
									ele.auction_status = true
									ele.auction_sold_value = null;
									ele.auction_sold_date = null;

								}
							});
						}
					})
					.catch(function (error) {
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		});
	},
	mounted() {
		let vm = this;
		vm.getCaseStages();
	},

	watch: {
		"form.number_type": function (val) {
			switch (val) {
				case 'CRIME_NO': this.number_type_label = 'Crime No.';
					break;
				case 'UDR_NO': this.number_type_label = 'UDR No.';
					break;
				case 'NCR_NO': this.number_type_label = 'NCR No.';
					break;
				case 'C_MISC': this.number_type_label = 'C Misc. No.';
					break;
				default: this.number_type_label = 'Crime No.'
			}
		}
	},
	methods: {
		getMuddemalFieldHistory(field) {
			let vm = this;
			vm.muddemal_history_status = true;
			vm.property_history_status = false;
			vm.muddemal_meta.field = field
			vm.muddemal_meta.muddemal_id = vm.form.muddemal_id
			vm.$store
				.dispatch("post", { uri: "getMuddemalEditHistory", data: vm.muddemal_meta })
				.then(function (response) {
					vm.muddemal_history = response.data.data
					vm.muddemal_meta.totalRows = response.data.meta.total;
					vm.muddemal_meta.lastPage = response.data.meta.last_page;
					vm.muddemal_meta.from = response.data.meta.from;
					vm.muddemal_meta.maxPage = vm.muddemal_meta.lastPage >= 3 ? 3 : vm.muddemal_meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		onPageChange(page) {
			this.history_meta.page = page;
			if (this.property_history_status) {
				this.getPropertyFieldHistory(this.property, this.history_meta.field);
			} else {
				this.getMuddemalFieldHistory(this.muddemal_history.field);
			}
		},
		search() {
			let vm = this;
			if (vm.property_history_status) {
				vm.history_meta.page = 1;
				vm.getPropertyFieldHistory(vm.property, vm.history_meta.field);
			} else {
				vm.muddemal_meta.page = 1;
				vm.getMuddemalFieldHistory(vm.muddemal_history.field);
			}
		},
		getPropertyFieldHistory(property, field) {
			let vm = this;
			vm.muddemal_history_status = false;
			vm.property_history_status = true;
			vm.property = property
			vm.history_meta.property_id = property.property_id
			vm.history_meta.field = field
			vm.history_meta.muddemal_id = vm.form.muddemal_id
			if (property.property_id) {
				vm.$store
					.dispatch("post", { uri: "getPropertyEditHistory", data: vm.history_meta })
					.then(function (response) {
						vm.field_history = response.data.data
						vm.history_meta.totalRows = response.data.meta.total;
						vm.history_meta.lastPage = response.data.meta.last_page;
						vm.history_meta.from = response.data.meta.from;
						vm.history_meta.maxPage = vm.history_meta.lastPage >= 3 ? 3 : vm.history_meta.lastPage;
						// vm.case_stages = response.data.data;
						// vm.getPoliceStations();
					})
					.catch(function (error) {
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
			} else {
				vm.field_history = [];
			}

		},

		changeDateFormat(date) {
			if (date) {
				return moment(date).format('YYYY-MM-DD HH:mm')
			}
		},
		checkPropertyType(property) {
			let vm = this
			let pr = this.property_types.filter(function (element) {
				return element.property_type_id == property.property_type_id
			})
			if (pr.length) {
				if (pr[0].property_type == 'Gold' || pr[0].property_type == 'Silver' || pr[0].property_type == 'Drugs') {
					property.amount_input_group = 'input-group'
					property.amount_width = 'width:90px'
					property.amount_display = 'display:block'
					property.amount_status = false
				} else {
					property.amount_input_group = ''
					property.amount_display = 'display:none'
					property.amount_width = 'width:135px'
					property.amount_status = true
					property.kilogram = ''
					property.grams = ''
				}
			}
			let property_type = this.property_types.find(ele => ele.property_type_id == property.property_type_id);
			if (property_type) {
				if (property_type.property_type == 'Others') {
					property.valuable_non_valuable = 'Non-Valuable';
					property.property_value_status = true;
				} else {
					property.valuable_non_valuable = 'Valuable';
					property.property_value_status = false;
				}
			}
		},
		getYearFromDate(date) {
			let vm = this;
			vm.form.year = new Date(date).getFullYear();
		},
		showColumn(key, value, property) {
			if (key == "remarks" && value == 1) {
				property.remarks_if_property_not_available = "";
				property.remarks_input_group = ''
				property.remarks_display = 'display:none'
				property.remarks_status = true
			} else {
				property.remarks_input_group = 'input-group'
				property.remarks_display = 'display:block'
				property.remarks_status = false
			}
		},
		showProperty(key, value, property) {
			let vm = this;
			if (key == "valuable" && value == "Valuable") {
				property.property_value_input_group = 'input-group'
				property.property_value_display = 'display:block'
				property.property_value_width = 'width:90px'
				property.property_value_status = false
			} else {
				property.property_value = "";
				property.property_value_input_group = ''
				property.property_value_display = 'display:none'
				property.property_value_width = 'width:133px'
				property.property_value_status = true
			}
		},
		getDisposedStatus(key, value, property) {
			let vm = this;
			if (key == "auction") {
				let disposal = vm.property_disposals.filter(function (ele) {
					return ele.property_disposal_stage_id == value;
				});
				if (disposal.length && disposal[0].property_disposal_stage == "Auction") {
					property.auction_status = false;
					property.auction_value_input_group = 'input-group'
					property.auction_value_display = 'display:block'
					property.auction_value_width = 'width:80px'
				} else {
					property.auction_status = true;
					property.auction_value_input_group = ''
					property.auction_value_display = 'display:none'
					property.auction_value_width = 'width:125px'
					property.auction_sold_value = null;
					property.auction_sold_date = null;
				}
			}
		},
		getDisposedStatus1(key, value, property) {
			let vm = this;
			if (key == "property_disposed" && value == true) {
				property.auction_status = true;
				property.disposed_input_group = 'input-group'
				property.disposed_display = 'display:block'
				property.disposed_width = 'width:155px'
				property.disposed_status = false

				// if(property.property_disposal_stage_id == null){
				//     property.auction_value_input_group = 'input-group'
				//     property.auction_value_display = 'display:block'
				//     property.auction_value_width = 'width:80px'
				//     property.auction_status = false;
				// }


			} else {
				property.property_disposal_stage_id = "null";
				property.auction_status = false;

				property.disposed_input_group = ''
				property.disposed_display = 'display:none'
				property.disposed_width = 'width:200px'
				property.disposed_status = true
				property.auction_sold_value = null;
				property.auction_sold_date = null;

				property.auction_value_input_group = ''
				property.auction_value_display = 'display:none'
				property.auction_value_width = 'width:125px'
				property.auction_status = true;
			}
		},

		addProperty() {
			let vm = this
			if (vm.form.properties.length == 0) {
				vm.form.properties.push({
					property_detail_id: "",
					property_type_id: "",
					property_description: "",
					quantity: 0,
					siezed_property_available: 1,
					remarks_if_property_not_available: "",
					valuable_non_valuable: "",
					property_value: "",
					property_photo: "",
					property_photo_name: "",
					image: "",
					property_type: "",
					officer_incharge: "",
					disposed: 0,
					property_disposal_stage_id: null,
					instructions_by_officer: "",
					auction_sold: null,
					disposed_date: null,
					auction_sold_value: null,
					auction_sold_date: null,
					remarks_input_group: '',
					remarks_display: 'display:none',
					property_value_display: 'display:none',
					property_value_width: 'width:133px',
					disposed_display: 'display:none',
					disposed_width: 'width:200px',
					auction_value_display: 'display:none',
					auction_value_width: 'width:125px',
					amount_display: 'display:none',
					amount_width: 'width:135px',
					remarks_status: true,
					errors: [],
				});
			} else {
				let previous_property = vm.form.properties[vm.form.properties.length - 1]
				previous_property.errors = [];
				if (!previous_property.property_type_id || !previous_property.property_description) {
					if (previous_property.property_type_id == "") {
						previous_property.errors.property_type_id = ["property_type_id field cannot be empty"];
					}
					if (previous_property.property_description == "") {
						previous_property.errors.property_description = ["property_description field cannot be empty"];
					}
					if (previous_property.disposed && !previous_property.property_disposal_stage_id) {
						previous_property.errors.property_disposal_stage_id = ["property disposed reason cannot be empty"];
					}
					if (previous_property.property_type_id) {
						let vm = this
						let pr = this.property_types.filter(function (element) {
							return element.property_type_id == previous_property.property_type_id
						})
						if (pr.length) {
							if ((pr[0].property_type == 'Gold' || pr[0].property_type == 'Silver' || pr[0].property_type.property_type == 'Drugs') && !previous_property.kilogram) {
								previous_property.errors.kilogram = ["kilogram field cannot be empty"]
							} else {
								previous_property.errors.kilogram = ""
							}
							if ((pr[0].property_type == 'Gold' || pr[0].property_type == 'Silver' || pr[0].property_type.property_type == 'Drugs') && !previous_property.grams) {
								previous_property.errors.grams = ["grams field cannot be empty"]
							} else {
								previous_property.errors.grams = ""
							}
						}
					}
					if (previous_property.siezed_property_available == "0" && !previous_property.remarks_if_property_not_available) {
						previous_property.errors.remarks_if_property_not_available = ["remarks if property not available cannot be empty"];
					}
					if (previous_property.valuable_non_valuable == "Valuable" && !previous_property.property_value) {
						previous_property.errors.property_value = ["property value cannot be empty"];
					}
					if (previous_property.disposed == "true" && previous_property.property_disposal_stage_id == null) {
						previous_property.errors.property_disposal_stage_id = ["property disposal stage id cannot be empty"];
					}
					if (previous_property.disposed == "true" && !previous_property.disposed_date) {
						previous_property.errors.disposed_date = ["disposed date cannot be empty"];
					}
					if (previous_property.property_disposal_stage_id) {
						let disposal = vm.property_disposals.filter(function (ele) {
							return ele.property_disposal_stage_id == previous_property.property_disposal_stage_id;
						});
						if (disposal.length && disposal[0].property_disposal_stage == "Auction") {
							if (previous_property.auction_sold == null) {
								previous_property.errors.auction_sold = ["auction value cannot be empty"];
							}
							if (previous_property.auction_sold_value == null) {
								previous_property.errors.auction_sold_value = ["auction value cannot be empty"];
							}
							if (!previous_property.auction_sold_date) {
								previous_property.errors.auction_sold_date = ["auction date cannot be empty"];
							}
						}
					}
				}

				if (previous_property.siezed_property_available) {
					if (previous_property.siezed_property_available == "0" && !previous_property.remarks_if_property_not_available) {
						previous_property.errors.remarks_if_property_not_available = ["remarks if property not available cannot be empty"];
					}
				}
				if (previous_property.valuable_non_valuable) {
					if (previous_property.valuable_non_valuable == "Valuable" && !previous_property.property_value) {
						previous_property.errors.property_value = ["property value cannot be empty"];
					}
				}
				if (previous_property.disposed) {
					if (previous_property.disposed == "true" && previous_property.property_disposal_stage_id == null) {
						previous_property.errors.property_disposal_stage_id = ["property disposal stage id cannot be empty"];
					}
					if (previous_property.disposed == "true" && !previous_property.disposed_date) {
						previous_property.errors.disposed_date = ["disposed date cannot be empty"];
					}
				}
				if (previous_property.property_disposal_stage_id) {
					let disposal = vm.property_disposals.filter(function (ele) {
						return ele.property_disposal_stage_id == previous_property.property_disposal_stage_id;
					});
					if (disposal.length && disposal[0].property_disposal_stage == "Auction") {
						if (previous_property.auction_sold_value == null) {
							previous_property.errors.auction_sold_value = ["auction sold value cannot be empty"];
						}
						if (!previous_property.auction_sold_date) {
							previous_property.errors.auction_sold_date = ["auction sold date cannot be empty"];
						}
						if (!previous_property.officer_incharge) {
							previous_property.errors.officer_incharge = ["officer incharge field cannot be empty"];
						}
					}
				}
				if (!Object.keys(previous_property.errors).length) {
					vm.form.properties.push({
						property_detail_id: "",
						property_type_id: "",
						property_description: "",
						siezed_property_available: 1,
						remarks_if_property_not_available: "",
						valuable_non_valuable: "",
						property_value: "",
						property_photo: "",
						property_photo_name: "",
						image: "",
						property_type: "",
						property_disposal_stage_id: null,
						disposed: 0,
						disposed_date: null,
						officer_incharge: "",
						instructions_by_officer: "",
						auction_sold: null,
						auction_sold_value: null,
						auction_sold_date: null,
						remarks_input_group: '',
						remarks_display: 'display:none',
						remarks_status: true,
						property_value_input_group: '',
						property_value_display: 'display:none',
						property_value_width: 'width:133px',
						property_value_status: true,
						disposed_input_group: '',
						disposed_display: 'display:none',
						disposed_width: 'width:200px',
						disposed_status: true,
						auction_value_input_group: '',
						auction_value_display: 'display:none',
						auction_value_width: 'width:125px',
						auction_status: true,
						amount_input_group: '',
						amount_display: 'display:none',
						amount_width: 'width:135px',
						amount_status: true,
						quantity: 0,
						errors: [],
					});
				}
			}
		},
		getDivision(police_station_id) {
			let vm = this;
			let police_Station = vm.police_stations.filter(function (ele) {
				return ele.police_station_id == police_station_id;
			});
			if (police_Station.length) {
				vm.form.division_id = police_Station[0]?.division?.division_id;
				vm.form.sub_division_id = police_Station[0]?.sub_division?.sub_division_id;
				if (police_Station[0]?.division?.division == 'CCB') {
					vm.getAllPoliceStations()
					vm.selected_division = 'CCB';
				}
				else {
					vm.form.transferred_ps_id = "";
					vm.selected_division = '';
				}
			}
		},
		getAllPoliceStations() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "getPoliceStations" })
				.then(function (response) {
					vm.all_police_stations = response.data.data;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		getProperty(property) {
			this.property.property_type = property.property_type;
		},
		getCaseStages() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "getCaseStages" })
				.then(function (response) {
					vm.case_stages = response.data.data;
					vm.getPoliceStations();
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		getPoliceStations() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "getUserPoliceStations" })
				.then(function (response) {
					vm.police_stations = response.data.data;
					if (vm.police_stations?.length && vm.status) {
						vm.form.police_station_id = vm.police_stations[0].police_station_id;
					}
					vm.getDivision(vm.form.police_station_id)
					vm.getDivisions();
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		getDivisions() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "getDivisions" })
				.then(function (response) {
					vm.divisions = response.data.data;
					vm.getSubDivisions();
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		getSubDivisions() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "getSubDivisions" })
				.then(function (response) {
					vm.sub_divisions = response.data.data;
					vm.getPropertyTypes();
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		getPropertyTypes() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "getPropertyTypes" })
				.then(function (response) {
					vm.property_types = response.data.data;
					vm.getPropertyDisposals();
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		getPropertyDisposals() {
			let vm = this;
			let uri = { uri: "getPropertyDisposalStages" };
			vm.$store
				.dispatch("post", uri)
				.then(function (response) {
					vm.property_disposals = response.data.data;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		discardForm() {
			let vm = this;
			vm.property.property_type_id = "";
			vm.property.property_description = "";
			vm.property.quantity = 0;
			vm.property.siezed_property_available = null;
			vm.property.remarks_if_property_not_available = "";
			vm.property.valuable_non_valuable = "";
			vm.property.property_value = "";
			vm.property.property_photo = "";
			vm.formStatus = true;
			vm.$refs.pf_number.focus();
			vm.errors = [];
		},
		deleteRow(property, key) {
			let vm = this;
			if (confirm("Do You want to Delete this Record?..")) {
				vm.form.deleted_properties.push(property.property_id), vm.form.properties.splice(key, 1);
				// vm.discardForm()
			}
		},
		getPropertyPhoto(e, property) {
			let vm = this;
			property.image = e.target.files[0];
			property.property_photo_name = property.image?.name;
			let reader = new FileReader();
			reader.readAsDataURL(property.image);
			reader.onload = (e) => {
				property.property_photo = e.target.result;
			};
			property.url = URL.createObjectURL(property.image);
		},

		updateForms() {
			let vm = this;
			let errs = [];
			let property = {
				errors: [],
			};
			let prop_error_counts = 0;
			if (vm.form.properties.length) {
				errs = [];
				vm.form.properties.filter(function (prop) {
					if (prop.disposed_date === "") {
						prop.disposed_date = null
					}
					if (prop.siezed_property_available === "" || prop.siezed_property_available === "null") {
						prop.siezed_property_available = null
					}
					prop.errors = [];
					if (
						!prop.property_type_id &&
						!prop.property_description &&
						!prop.siezed_property_available &&
						!prop.remarks_if_property_not_available &&
						!prop.valuable_non_valuable &&
						!prop.property_value &&
						!prop.disposed &&
						!prop.property_disposal_stage_id
					) {
						vm.form.properties.pop(prop);
					}
					if (prop.property_type_id == "" && vm.form.properties.length != 0) {
						prop.errors.property_type_id = ["property_type_id field cannot be empty"];
					}
					if (prop.property_type_id) {
						let pr = vm.property_types.filter(function (element) {
							return element.property_type_id == prop.property_type_id
						})
						if (pr.length) {
							if ((pr[0].property_type == 'Gold' || pr[0].property_type == 'Silver' || pr[0].property_type == 'Drugs') && (!prop.kilogram && !prop.grams)) {
								prop.errors.kilogram = ["kilogram field cannot be empty"]
								prop.errors.grams = ["Grams field cannot be empty"]
								errs.kilogram = ["kilogram field cannot be empty"]
								errs.grams = ["Grams field cannot be empty"]
							}
							// if((pr[0].property_type == 'Gold' || pr[0].property_type == 'Silver' || pr[0].property_type.property_type == 'Drugs') && !prop.kilogram){
							//     prop.errors.kilogram = ["kilogram field cannot be empty"]
							//     errs.kilogram = ["kilogram field cannot be empty"]
							// }
							// if((pr[0].property_type == 'Gold' || pr[0].property_type == 'Silver' || pr[0].property_type.property_type == 'Drugs') && !prop.grams){
							//     prop.errors.grams = ["grams field cannot be empty"]
							//     errs.grams = ["grams field cannot be empty"]
							// }
							if (prop.valuable_non_valuable == '') {
								prop.errors.valuable_non_valuable = ["This field cannot be empty."]
							}
						}
					}
					if (vm.form.properties.length && prop.property_description == "") {
						prop.errors.property_description = ["property_description field cannot be empty"];
						errs.property_description = ["property_description field cannot be empty"];
					}
					if (prop.siezed_property_available == "0" && !prop.remarks_if_property_not_available) {
						prop.errors.remarks_if_property_not_available = ["remarks if property not available cannot be empty"];
						errs.remarks_if_property_not_available = ["remarks if property not available cannot be empty"];
					}
					if (prop.valuable_non_valuable == "Valuable" && !prop.property_value) {
						if (prop.property_value == 0) {

						} else {
							prop.errors.property_value = ["property value cannot be empty"];
							errs.property_value = ["property value cannot be empty"];
						}
					}
					if (prop.disposed == 1 && prop.property_disposal_stage_id == null) {
						prop.errors.property_disposal_stage_id = ["property disposal stage id cannot be empty"];
						errs.property_disposal_stage_id = ["property disposal stage id cannot be empty"];
					}
					if (prop.disposed == 1 && !prop.disposed_date) {
						prop.errors.disposed_date = ["disposed date cannot be empty"];
						errs.disposed_date = ["disposed date cannot be empty"];
					}
					if (prop.disposed == 1 && !prop.officer_incharge) {
						prop.errors.officer_incharge = ["Officer incharge field cannot be empty"];
						errs.officer_incharge = ["Officer incharge field cannot be empty"];
					}
					if (prop.property_disposal_stage_id) {
						let disposal = vm.property_disposals.filter(function (ele) {
							return ele.property_disposal_stage_id == prop.property_disposal_stage_id;
						});
						if (disposal.length && disposal[0].property_disposal_stage == "Auction") {
							if (prop.auction_sold_value == null) {
								prop.errors.auction_sold_value = ["auction sold value cannot be empty"];
								errs.auction_sold_value = ["auction sold value cannot be empty"];
							}
							if (!prop.auction_sold_date) {
								prop.errors.auction_sold_date = ["auction sold date cannot be empty"];
								errs.auction_sold_date = ["auction sold date cannot be empty"];
							}
						}
					}
					prop_error_counts = Object.keys(prop.errors)?.length;
				});
			}
			if (!Object.keys(errs).length) {
				if (prop_error_counts != 0) {
					return;
				}

				let properties = [];
				properties.push(vm.form.properties);
				let data = new FormData();
				data.append("muddemal_id", this.form.muddemal_id);
				data.append("pf_number", this.form.pf_number);
				data.append("pf_date", this.form.pf_date);
				data.append("year", this.form.year);
				data.append("crime_no_sec", this.form.crime_no_sec);
				data.append("number_type", this.form.number_type);
				data.append("section_of_law", this.form.section_of_law);
				data.append("investigation_officer", this.form.investigation_officer);
				data.append("siezed_from_whom", this.form.siezed_from_whom);
				data.append("siezed_from_place", this.form.siezed_from_place);
				data.append("cc_sc_no", this.form.cc_sc_no);
				data.append("case_stage_id", this.form.case_stage_id);
				data.append("pr_no", this.form.pr_no);
				data.append("pr_date", this.form.pr_date);
				data.append("holding_officer", this.form.holding_officer);
				data.append("police_station_id", this.form.police_station_id);
				data.append("sub_division_id", this.form.sub_division_id);
				data.append("division_id", this.form.division_id);
				data.append("transferred_ps_id", this.form.transferred_ps_id);
				data.append("muddemal_status", this.form.muddemal_status);
				data.append("properties", JSON.stringify(vm.form.properties));
				data.append("deleted_properties", JSON.stringify(vm.form.deleted_properties));

				let loader = vm.$loading.show();
				axios
					.post(vm.$store.state.apiUrl + "api/updateMuddemal", data, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: "Bearer" + " " + vm.$store.getters.token,
						},
					})
					.then((response) => {
						loader.hide();
						vm.$store.dispatch("success", "Muddemal Updated successfully");
						vm.$router.push("/muddemal");
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						if (vm.errors) {
							if (vm.form.properties.length == 0) {
								vm.addProperty();
							}
						}
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		},

		getMeasuringUnit(property) {
			return this.property_types.find(ele => ele.property_type_id === property.property_type_id)?.measuring_unit;
		},

		isNumber(e) {
			const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
			const keyPressed = e.key;
			if (!keysAllowed.includes(keyPressed)) {
				e.preventDefault()
			}
		},

		deletePropertyImage(property) {
			if (property.image) {
				property.property_photo_name = null;
				property.property_photo = null;
				return;
			}
			if (!confirm('Property image will be deleted permanently.')) return;

			let vm = this;
			let loader = this.$loading.show();
			vm.$store.dispatch('post', { uri: 'deletePropertyImage', data: { property_id: property.property_id } })
				.then(response => {
					loader.hide();
					const index = vm.form.properties.findIndex(ele => ele.property_id === property.property_id);
					let temp = response.data.property;
					temp.remarks_display = 'display:none';
					temp.property_value_display = 'display:none';
					temp.property_value_width = 'width:133px';
					temp.disposed_display = 'display:none';
					temp.disposed_width = 'width:200px';
					temp.auction_value_display = 'display:none';
					temp.auction_value_width = 'width:125px';
					temp.amount_display = 'display:none';
					temp.amount_width = 'width:135px';
					if (index !== -1) {
						vm.form.properties[index] = temp;
					}

					vm.$store.dispatch('success', response.data.message);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		}
	},
};
</script>

<style scoped>
.modal-img {
	width: -webkit-fill-available;
}

.modal-close {
	background-color: white;
	border-radius: 50%;
	padding: 5px;
}

input:focus,
select:focus {
	outline: none;
	box-shadow: 0 0 0 0 transparent;
}

.menu_icon {
	font-size: 20px;
	opacity: 0.85;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.right-inner-addon {
	position: relative;
}

.right-inner-addon input {
	padding-right: 35px !important;
}

.right-inner-addon i {
	position: absolute;
	right: 0px;
	padding: 9px 9px;
}

.input-group {
	position: relative !important;
	display: flex !important;
	flex-wrap: nowrap !important;
	align-items: stretch !important;
	width: 100% !important;
}

.input-group-append {
	background-color: transparent !important;

}

.input-group-text {
	background-color: transparent !important;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
}

.input-group select,
.input-group input,
.border-right-0 {
	border-right: 0px !important
}

.lh-1-2 {
	line-height: 1.2 !important;
}

.border-left-0 {
	border-left: 0px;
}

.input-group-text.is-invalid {
	border-color: #dc3545;
	padding-right: calc(1.5em + 0.812rem);
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.203rem) center;
	background-size: calc(0.75em + 0.406rem) calc(0.75em + 0.406rem);
}

.input-group input,
select:focus {
	outline: none;
	box-shadow: none !important;
}
</style>
